<template>
  <ion-footer translucent="true">
    <reschedule-block
      v-if="lesson?.changes" :lesson="lesson" :current-user="currentUserType"
      @changes:approve="answerRescheduleRequest(true)"
      @changes:decline="answerRescheduleRequest(false)"
    />
    <ion-toolbar v-if="status.actionable && !status.needToAnswerRescheduleRequest" data-testid="lesson-actions">
      <ion-grid>
        <ion-row>
          <ion-col v-show="status.canDecline">
            <ion-button
              v-show="!lesson.isRescheduleRequested()" color="danger" fill="outline"
              expand="block" data-testid="lesson-decline" @click="declineLessonAlert"
            >
              <ion-icon slot="start" :icon="close" />
              Decline
            </ion-button>
          </ion-col>
          <ion-col v-show="status.canCancel">
            <ion-button
              data-testid="lesson-cancel" color="danger"
              fill="outline" expand="block" @click="showCancellationModal"
            >
              <ion-icon slot="start" :icon="ban" />
              Cancel
            </ion-button>
          </ion-col>
          <ion-col v-show="status.canReschedule">
            <ion-button
              expand="block" color="primary" data-testid="lesson-reschedule"
              fill="outline" :router-link="{name: 'lessonEditPage', params: {lessonId: lesson.id}}"
            >
              <ion-icon slot="start" :icon="calendarOutline" />
              Reschedule
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col v-show="status.canConfirm">
            <ion-button
              v-show="!lesson.isRescheduleRequested()" data-testid="lesson-confirm"
              expand="block" color="success" fill="solid"
              @click="confirmLessonAlert"
            >
              <ion-icon slot="start" :icon="checkmarkOutline" />
              Confirm
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</template>

<script setup>
import {
  IonButton, IonCol, IonFooter, IonGrid, IonIcon,
  IonRow, IonToolbar, alertController, modalController,
} from '@ionic/vue'
import { ban, calendarOutline, checkmarkOutline, close } from 'ionicons/icons'
import { computed } from 'vue'
import { formatDate } from 'date-fns'

import CancelLessonModal from '@/shared/components/LessonActions/CancelLessonModal.vue'
import RescheduleBlock from '@/views/golfers/lessons/RescheduleBlock.vue'
import { sendRequest } from '@/6_shared/utils/sendRequest'

const props = defineProps({
  lesson: { type: Object, required: true },
  currentUserType: { type: String, required: true },
})
const emit = defineEmits(['lesson:confirm', 'lesson:decline', 'lesson:cancel'])

async function confirmLessonAlert() {
  const alert = await alertController.create({
    header: 'Confirm Lesson',
    message: 'Confirm your availability. Golfer will be charged for the lesson and notified.',
    buttons: [
      { text: 'Cancel', role: 'cancel' },
      { text: 'Confirm', role: 'confirm', handler: () => emit('lesson:confirm') },
    ],
  })
  await alert.present()
}

async function declineLessonAlert() {
  const alert = await alertController.create({
    header: 'Decline Lesson',
    message: 'Please provide a reason for declining the lesson request. Golfer will be notified.',
    buttons: [
      { text: 'Cancel', role: 'cancel' },
      { text: 'Decline', role: 'destructive', handler: declineReason => emit('lesson:decline', declineReason[0]) },
    ],
    inputs: [{ type: 'textarea', placeholder: 'optional...' }],
  })
  await alert.present()
}

async function showCancellationModal() {
  const modal = await modalController.create({
    component: CancelLessonModal,
    componentProps: { currentUserType: props.currentUserType },
    cssClass: 'cancel-lesson-modal',
  })
  await modal.present()
  const { data: cancellationReason, role } = await modal.onWillDismiss()
  if (role === 'confirmCancellation') {
    emit('lesson:cancel', cancellationReason)
  }
}

function lessonDayPassed() {
  return formatDate(new Date(), 'yyyy-MM-dd') >= props.lesson.date
}

const status = computed(() => {
  return {
    waitingConfirmationByInstructor: props.lesson.isPendingConfirmation() && props.currentUserType === 'instructor',
    canReschedule: props.currentUserType === 'instructor' || (props.currentUserType === 'student' && !lessonDayPassed()),
    canDecline: props.lesson.isPendingConfirmation() && props.currentUserType === 'instructor',
    canConfirm: props.lesson.isPendingConfirmation() && props.currentUserType === 'instructor',
    canCancel: (!props.lesson.isPendingConfirmation() && props.currentUserType === 'instructor') || (props.currentUserType === 'student' && !lessonDayPassed()),
    actionable: !props.lesson.isDeclinedByInstructor() && !props.lesson.isCancelled() && !props.lesson.isCompleted(),
    needToAnswerRescheduleRequest: props.lesson.isRescheduleRequested() && props.lesson.changes.requestor.type !== props.currentUserType,
  }
})

import { LessonsRepository } from '@/data/LessonsRepository'
const lessonsRepo = new LessonsRepository()
async function answerRescheduleRequest(approved) {
  const message = approved ? 'Approving' : 'Declining'
  await sendRequest(`${message} Reschedule Request...`, async () => {
    await lessonsRepo.answerRescheduleRequest(props.lesson, approved)
  }, props.lesson)
}

</script>
