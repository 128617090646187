import LogRocket from 'logrocket'

import * as Sentry from '@sentry/vue'
import { VueFire, VueFireAuth } from 'vuefire'
import { createApp } from 'vue'
import { firebaseApp } from '@/firebase'

import App from '@/App.vue'
import { SM } from './globals'
import router from '@/router'

import { IonicVue } from '@ionic/vue'

if (SM.env === 'production') {
  LogRocket.init('btxkfr/swingmatch')
}

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

import { createPinia } from 'pinia'
const pinia = createPinia()

// Disable dark mode for now
// import '@ionic/vue/css/palettes/dark.system.css'

/* Theme variables */
import './theme/variables.css'
import './theme/desktop.css'
import 'animate.css'

const app = createApp(App)
  .use(IonicVue, { mode: SM.forcedPlatform, swipeBackEnabled: false, innerHTMLTemplatesEnabled: true })
  .use(router)
  .use(pinia)
  .use(VueFire, {
    firebaseApp,
    modules: [
      VueFireAuth(),
    ],
  })

if (SM.env === 'production') {
  Sentry.init({
    app,
    dsn: 'https://c5830cfa7e28b2bc6992d9d95d983470@o4507448769642496.ingest.us.sentry.io/4507448774885376',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/us-west1-swingmatch-8a936\.cloudfunctions\.net/, /^https:\/\/app\.swingmatch\.ca/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

router.isReady().then(() => {
  app.mount('#app')
})
