<template>
  <div>
    <h3>Chat Rules:</h3>
    <ul>
      <li>Be kind and respectful. 😊</li>
      <li>Keep it positive and fun.</li>
      <li>Stay on topic and avoid spam.</li>
      <li>No hateful or offensive language.</li>
      <li>Let's build a great community together! 🙌</li>
    </ul>
  </div>
</template>
