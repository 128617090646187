<template>
  <div style="min-height:60px">
    <div v-show="goodToGo" class="ion-padding">
      <ion-segment v-if="paymentMethodsArray.length > 1 && !partOfPackageLesson" v-model="selectedPaymentMethod" scrollable="true">
        <ion-segment-button v-if="paymentMethodsArray.includes('creditCard')" value="creditCard">
          Pay Online
        </ion-segment-button>
        <ion-segment-button v-if="paymentMethodsArray.includes('offline')" value="offline">
          Already Paid / Pay Later
        </ion-segment-button>
      </ion-segment>
      <div v-if="partOfPackageLesson">
        <h4>{{ stripeSession.metadata.name }}</h4>
        <ion-button
          fill="solid" color="success" expand="block"
          class="ion-margin-top" data-testid="offline-payment-book-button" @click="completePayment"
        >
          Book Lesson
        </ion-button>
      </div>
      <div v-if="selectedPaymentMethod == 'offline' && !partOfPackageLesson">
        <div v-if="stripeSession?.metadata?.paymentMethod != 'offline'">
          <ion-spinner />
        </div>
        <div v-else>
          <h4>{{ stripeSession.metadata.name }}</h4>
          <span v-if="userStore.is.student">
            {{ stripeSession.metadata.instructorName }} will ensure you have paid offline.
          </span>
          <span v-if="userStore.is.instructor">
            You will handle payment offline.
          </span>
          <ion-button
            fill="solid" color="success" expand="block"
            class="ion-margin-top" data-testid="offline-payment-book-button" @click="completePayment"
          >
            <span v-if="mode == 'timeslot'">Book Lesson</span>
            <span v-if="mode == 'package'">Book Package</span>
          </ion-button>
        </div>
      </div>
      <div v-show="selectedPaymentMethod == 'creditCard' && !partOfPackageLesson" id="checkout">
        <!-- Checkout will insert the payment form here -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { IonButton, IonSegment, IonSegmentButton, IonSpinner } from '@ionic/vue'
import { computed, onBeforeUnmount, ref, watch } from 'vue'

import { createStripeSession, createStripeSessionForPackage } from '@/firebase'
import { usePaymentCheckout } from './composables/usePaymentCheckout'
import { useUserStore } from '@/stores/user'

const props = defineProps({
  goodToGo: { required: true, type: Boolean },
  mode: {
    required: true,
    type: String,
    validator: value => ['timeslot', 'package'].includes(value),
  },
  timeslot: {
    required: false,
    type: Object,
    default: () => ({}),
  },
  package: {
    required: false,
    type: Object,
    default: () => ({}),
  },
  paymentMethods: {
    required: true,
    type: Object,
    default: () => ({ creditCard: true, offline: false }),
  },
  demoMode: {
    required: false,
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['payment-complete'])
const userStore = useUserStore()

// Payment methods handling
const paymentMethodsArray = Object.keys(props.paymentMethods)
  .filter(key => props.paymentMethods[key])
  .sort()
const selectedPaymentMethod = ref(paymentMethodsArray[0])
const stripeSession = ref(null)

// Handle payment completion
function completePayment() {
  const paymentInfo = { ...stripeSession.value }
  delete paymentInfo.clientSecret
  emit('payment-complete', paymentInfo)
  cleanUpCheckout()
}

// Get session data based on mode
async function getSessionData() {
  if (props.mode === 'timeslot') {
    const response = await createStripeSession({
      timeslot: props.timeslot,
      paymentMethod: selectedPaymentMethod.value,
    })
    return response.data
  }

  const response = await createStripeSessionForPackage({
    pckg: props.package,
    paymentMethod: selectedPaymentMethod.value,
  })
  return response.data
}

// Checkout handling
const {
  initializeCheckout,
  cleanUpCheckout,
} = usePaymentCheckout({
  demoMode: props.demoMode,
  selectedPaymentMethod,
  onComplete: completePayment,
  getSessionData,
  stripeSession,
})

// Computed properties
const partOfPackageLesson = computed(() =>
  props.mode === 'timeslot'
  && stripeSession.value?.metadata?.packageId
  && stripeSession.value?.metadata?.pricingPrice === 0,
)

// Watchers
watch(selectedPaymentMethod, async () => {
  initializeCheckout(selectedPaymentMethod.value)
})

// Lifecycle
onBeforeUnmount(() => {
  cleanUpCheckout()
})

// Initial setup
initializeCheckout(selectedPaymentMethod.value)
</script>
