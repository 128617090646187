<template>
  <ion-avatar :style="`height:${size}px;width:${size}px;`">
    <template v-if="url">
      <img :src="url" data-testid="user-avatar">
    </template>
    <template v-else-if="name">
      <div
        class="initials-avatar"
        :style="`height:${size}px;width:${size}px;font-size:${size/2.5}px`"
        data-testid="user-avatar-initials"
      >
        {{ getInitials }}
      </div>
    </template>
    <template v-else>
      <img src="/images/user_no_photo.png" data-testid="user-avatar">
    </template>
  </ion-avatar>
</template>

<script setup>
import { IonAvatar } from '@ionic/vue'
import { computed } from 'vue'

const props = defineProps({
  url: { type: String, default: '' },
  size: { default: 70, type: Number },
  name: { type: String, default: '' },
})

const getInitials = computed(() => {
  return props.name
    .split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .slice(0, 2)
    .join('')
})
</script>

<style scoped>
.initials-avatar {
  background-color: #e0e0e0;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 500;
}
</style>
