<template>
  <ion-item
    detail="true" class="lesson-row" lines="full"
    button="true" :router-link="`/my/lessons/${lesson.id}`"
  >
    <ion-label>
      <div class="lesson-header">
        <div class="left-content">
          <div class="datetime">{{ lesson.formattedDate }}, {{ displayFullLength(lesson.time, lesson.length) }}</div>
          <div class="student-name">{{ lesson.student.name }}</div>
        </div>
        <div class="right-content">
          <div v-if="!lesson.paymentInfoObject().package" class="price">
            {{ formatMoney(lesson.paymentInfoObject().price) }}
            <ion-text
              :color="getStatusColor(lesson.paymentInfoObject().status, lesson.paymentInfoObject().method)"
              size="small"
            >
              {{ lesson.paymentInfoObject().status }}
            </ion-text>
          </div>
          <div class="payment-info">
            <span class="method">
              <span v-if="lesson.paymentInfoObject().package" class="package-indicator">
                part of package
              </span>
              <span v-else>
                {{ lesson.paymentInfoObject().method === 'offline' ? 'offline' : 'credit card' }}
              </span>
            </span>
          </div>
        </div>
      </div>
    </ion-label>
  </ion-item>
</template>

<script setup>
import { IonItem, IonLabel, IonText } from '@ionic/vue'

import { displayFullLength } from '@/6_shared/utils/formattingLessons.js'
import { formatMoney } from '@/shared/utils/formatMoney'

defineProps({
  lesson: {
    type: Object,
    required: true,
  },
})

const getStatusColor = (status) => {
  switch (status) {
    case 'paid':
      return 'success'
    case 'authorized':
      return 'primary'
    default:
      return 'medium'
  }
}
</script>

<style scoped>
.lesson-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0.25rem 0;
}

.left-content {
  flex: 1;
}

.right-content {
  text-align: right;
  min-width: 120px;
}

.datetime {
  font-size: 0.9rem;
  color: var(--ion-color-medium);
  margin-bottom: 0.25rem;
}

.student-name {
  font-weight: 600;
  color: var(--ion-color-dark);
  font-size: 1rem;
}

.price {
  font-size: 1.1rem;
  color: var(--ion-color-dark);
  margin-bottom: 0.25rem;
}

.payment-info {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
}

.method {
  color: var(--ion-color-medium);
  font-size: 0.8rem;
}
</style>
