<template>
  <ion-toolbar v-if="changes" data-testid="lesson-reschedule-block">
    <!-- declined -->
    <div v-if="status === 'declined'" class="ion-text-center">
      <ion-note color="dark">
        Reschedule to
        <strong>{{ newDateAndTimeFormatted }} </strong>
        was <span style="color: var(--ion-color-danger)">declined</span>.
        <br>
        <span style="color: var(--ion-color-success)">Original time stays.</span>
      </ion-note>
    </div>

    <!-- approved -->
    <div v-if="status === 'approved'" class="ion-text-center">
      <ion-note v-if="!lesson.offlineStudent()" color="dark">
        Reschedule to
        <strong>{{ newDateAndTimeFormatted }} </strong>
        was <span style="color: var(--ion-color-success)">approved</span>.
      </ion-note>
      <ion-note v-if="lesson.offlineStudent()" color="dark">
        Rescheduled to
        <strong>{{ newDateAndTimeFormatted }}</strong>
      </ion-note>
    </div>

    <!-- pending -->
    <div v-if="status === 'pending'" class="ion-no-margin ion-margin-top ion-text-center">
      <ion-note color="dark">
        Reschedule to
        <strong style="color:var(--ion-color-primary, #0054e9)">{{ newDateAndTimeFormatted }} </strong>
        requested.
      </ion-note>
      <ion-grid v-if="changes.requestor.type != currentUser" data-testid="lesson-reschedule-buttons">
        <ion-row>
          <ion-col>
            <ion-button
              fill="solid" color="danger" expand="block"
              @click="emit('changes:decline')"
            >
              <ion-icon slot="start" :icon="thumbsDown" /> Decline
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button
              fill="solid" color="success" expand="block"
              @click="emit('changes:approve')"
            >
              <ion-icon slot="start" :icon="thumbsUp" /> Approve
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-toolbar>
</template>

<script setup>
import {
  IonButton, IonCol,
  IonGrid, IonIcon, IonNote, IonRow, IonToolbar,
} from '@ionic/vue'
import { thumbsDown, thumbsUp } from 'ionicons/icons'
import { computed } from 'vue'
import { formatTime } from '@/6_shared/utils/formattingLessons'

const props = defineProps({
  lesson: { type: Object, required: true },
  currentUser: { type: String, required: true },
})

const changes = computed(() => props.lesson.changes)

const status = computed(() => {
  const studentApproved = changes.value.approvals.student.approved
  const instructorApproved = changes.value.approvals.instructor.approved

  if (studentApproved === null || instructorApproved === null) {
    return 'pending'
  } else if (studentApproved && instructorApproved) {
    return 'approved'
  } else {
    return 'declined'
  }
})

const newDateAndTimeFormatted = computed(() =>
  `${props.lesson.changes.new.formattedDate}, ${formatTime(props.lesson.changes.new.time)}`,
)

const emit = defineEmits(['changes:approve', 'changes:decline'])

</script>
