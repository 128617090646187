/**
 * Formats a number as USD currency
 * @param {number} amount - The amount to format
 * @returns {string} Formatted currency string (e.g., "$1,234.50")
 */
export function formatMoney(amount: number): string {
  const isRoundNumber = amount % 1 === 0
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: isRoundNumber ? 0 : 2,
    maximumFractionDigits: 2,
  }).format(amount)
}
