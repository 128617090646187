<template>
  <ion-page>
    <ion-tabs>
      <sm-header v-if="!currentUser" />
      <ion-router-outlet />
      <ion-modal
        id="login-modal" ref="loginModalRef" handle="false"
        mode="md" @will-dismiss="hideLoginModal"
      >
        <sign-in-block />
      </ion-modal>
      <ion-tab-bar slot="bottom" data-testid="guest-tabs-menu">
        <ion-tab-button tab="account" @click="$router.push({ query: { show: 'login', redirect: $route.query.redirect }})">
          <ion-button
            size="small" color="success" fill="solid"
            style="white-space: nowrap"
          >
            Sign Up / Login
          </ion-button>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup>
import {
  IonButton, IonModal, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs,
} from '@ionic/vue'
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Intercom from '@intercom/messenger-js-sdk'
import { useCurrentUser } from 'vuefire'

import SignInBlock from '@/3_widgets/login/SignInBlock.vue'
import SmHeader from '@/views/layout/SmHeader.vue'

const loginModalRef = ref()
const route = useRoute()
const router = useRouter()
const currentUser = useCurrentUser()

watch(route, () => {
  if (route.query.show === 'login') {
    // Timeout is required to display login on page reload
    setTimeout(() => loginModalRef.value?.$el?.present(), 50)
  } else {
    loginModalRef.value?.$el?.dismiss()
  }
}, { immediate: true })

onBeforeUnmount(async () => {
  await loginModalRef.value?.$el?.dismiss()
})

function hideLoginModal() {
  if (route.query.show === 'login') router.back()
}

onMounted(() => {
  const intercomData = {
    app_id: 'dfro638p',
    custom_launcher_selector: '#intercom_custom_launcher',
  }
  Intercom(intercomData)
})

</script>
