<template>
  <ion-page>
    <ion-content :fullscreen="false" color="light">
      <!-- <random-fact /> -->
      <ion-card v-if="userStore.is.student">
        <ion-card-header>
          <ion-card-title>Welcome to SwingMatch!</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <p>
            Thank you for joining SwingMatch, your gateway to better golf! We're excited to help you improve your game and connect with the perfect instructor.
          </p>

          <p style="text-align:center" class="ion-padding-top">
            <ion-button color="success" :router-link="{name: 'newLesson'}">Book a Lesson</ion-button>
          </p>
        </ion-card-content>
      </ion-card>
      <upcoming-lessons />
      <purchased-packages-block v-if="userStore.is.student" />
      <ion-card>
        <ion-card-header>
          <ion-card-title>Contact Us</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <p>
            If you have any questions or concerns, please reach out to us at <a href="mailto:chrisotto@swingmatch.ca">chrisotto@swingmatch.ca</a>.
          </p>
        </ion-card-content>
      </ion-card>
      <share-profile-block />
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage } from '@ionic/vue'

import PurchasedPackagesBlock from '@/3_widgets/packages/PurchasedPackagesBlock.vue'
import ShareProfileBlock from '@/3_widgets/homepage/ShareProfileBlock.vue'
import UpcomingLessons from '@/3_widgets/homepage/UpcomingLessons.vue'

import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
</script>

<!-- <style>
ion-content::part(scroll) {
  background-image: url("@/assets/background1.jpeg");
  background-color: rgba(255, 255, 255, 0.3);
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
ion-list, ion-item {
  --ion-item-background: transparent;
}
</style> -->
