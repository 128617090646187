<template>
  <ion-page data-testid="multi-time-picker-modal">
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ lessonLength }} min lesson</ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{name: 'schedules'}" @click="cancel" />
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar class="ion-padding-horizontal">
        <ion-label data-testid="selected-times-label">
          <span>{{ sortedSelectedTimes.join(', ') }}</span>
          <span v-if="sortedSelectedTimes.length == 0">
            Select available timeslots
          </span>
        </ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div v-for="(timeGroup, groupIndex) in timeGroups" :key="groupIndex" class="time-group">
        <div class="time-grid">
          <div
            v-for="time in timeGroup"
            :key="time.data"
            class="time-slot"
          >
            <ion-checkbox
              :checked="selectedTimes.includes(time.data)"
              :disabled="notAvailable(time.data)"
              label-placement="end"
              justify="start"
              @ion-change="toggleTime(time.data)"
            >
              <strong v-if="time.cssClass">{{ time.text }}</strong>
              <span v-else>{{ time.text }}</span>
            </ion-checkbox>
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-button
              :disabled="!selectedTimes.length" expand="block"
              data-testid="multi-time-picker-modal-save-button" @click="confirm"
            >
              Save
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </ion-page>
</template>

<script setup>
import {
  IonBackButton, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonLabel, IonPage, IonRow, IonTitle, IonToolbar, modalController,
} from '@ionic/vue'
import { chunk, pull } from 'lodash'
import { computed, ref } from 'vue'

import { formatTime } from '@/6_shared/utils/formattingLessons'
import { generateTimes } from '@/4_features/TimePicker'

const props = defineProps({
  existingTimes: {
    type: Array,
    required: true,
    default: () => [],
  },
  lessonLength: {
    type: Number,
    required: true,
  },
})

const selectedTimes = ref([...props.existingTimes])
const availableTimes = generateTimes()

const timeGroups = computed(() => chunk(availableTimes, 4))

const sortedSelectedTimes = computed(() => [...selectedTimes.value].sort().map(time => formatTime(time, { lowercase: true, includeZeroes: true })))

function cancel() {
  modalController.dismiss(null, 'cancel')
}

function confirm() {
  modalController.dismiss(selectedTimes.value.sort(), 'confirm')
}

function toggleTime(timeValue) {
  if (selectedTimes.value.includes(timeValue)) {
    pull(selectedTimes.value, timeValue)
  } else {
    selectedTimes.value.push(timeValue)
  }
}

// Check if the time is available based on the lesson length:
// If 7 am is selected and the lesson is 45 min, then 7:30 am is not available
// The 7am should be still available for the user to uncheck
function notAvailable(timeValue) {
  const allTimes = selectedTimes.value
  if (allTimes.includes(timeValue)) return false // allow unchecking the time itself

  // Convert timeValue to minutes since midnight for easier comparison
  const [hours, minutes] = timeValue.split(':').map(Number)
  const timeInMinutes = hours * 60 + minutes

  for (const existingTime of allTimes) {
    const [existingHours, existingMinutes] = existingTime.split(':').map(Number)
    const existingTimeInMinutes = existingHours * 60 + existingMinutes

    // Calculate end time of existing lesson
    const existingEndTime = existingTimeInMinutes + props.lessonLength

    // Calculate end time of potential new lesson
    const newEndTime = timeInMinutes + props.lessonLength

    // Check if times overlap
    if (
      // New lesson starts during existing lesson
      (timeInMinutes >= existingTimeInMinutes && timeInMinutes < existingEndTime)
      // New lesson ends during existing lesson
      || (newEndTime > existingTimeInMinutes && newEndTime <= existingEndTime)
      // New lesson completely contains existing lesson
      || (timeInMinutes <= existingTimeInMinutes && newEndTime >= existingEndTime)
    ) {
      return true
    }
  }

  return false
}
</script>

<style scoped>
.time-group {
  margin-top: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid var(--ion-color-light-shade);
}

.time-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
  padding: 0;
}

.time-slot {
  min-height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
}
</style>
