<template>
  <ion-list :inset="editable" data-testid="lesson-type-card" class="ion-no-padding">
    <ion-item
      lines="full" :color="!editable ? 'light' : ''"
      :button="editable" :detail="editable"
      data-testid="schedule-info" @click="editLessonType('general')"
    >
      <ion-grid class="ion-margin-bottom">
        <ion-row>
          <ion-col size="4">
            <ion-icon :icon="golfOutline" size="large" color="medium" />
          </ion-col>
          <ion-col>
            <p style="margin-top: 0">
              <strong> {{ lessonType.location?.name }} &nbsp;</strong>
              <br>
              <ion-note>{{ lessonType.length }} min&nbsp;</ion-note>
            </p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="4">
            <ion-label><strong>Single Lessons:</strong></ion-label>
          </ion-col>
          <ion-col class="ion-no-padding">
            <ion-grid>
              <ion-row v-for="price in formatPricing(lessonType.pricing)" :key="price" class="ion-no-padding">
                <ion-col class="ion-no-padding">
                  {{ price[0] }}
                </ion-col>
                <ion-col size="auto" class="ion-no-padding">
                  ${{ price[1] }}
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-col>
        </ion-row>
        <ion-row v-if="packages.length">
          <ion-col size="4">
            <ion-label><strong>Lesson Packages:</strong></ion-label>
          </ion-col>
          <ion-col class="ion-no-padding">
            <ion-grid>
              <ion-row v-for="pkg in packages" :key="pkg" class="ion-no-padding">
                <ion-col class="ion-no-padding">
                  {{ pkg.lessons }}
                  <ion-note>x</ion-note>
                  {{ pkg.name }}
                </ion-col>
                <ion-col size="auto" class="ion-no-padding">
                  ${{ pkg.price }}
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-col>
        </ion-row>
        <ion-row v-if="!editable && lessonType.description.length">
          <ion-col>
            <p><em>{{ lessonType.description }}</em></p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
    <template v-if="editable">
      <!-- Commented out weekly schedules -->
      <!-- <ion-item lines="none" @click="editLessonType('schedule')">
        <ion-icon slot="start" :icon="repeatOutline" />
        <schedule-card mode="weekly" :lesson-type="lessonType" />
      </ion-item> -->
      <ion-item
        lines="none" :detail="editable" :button="editable"
        data-testid="schedule-dates" @click="editLessonType('schedule')"
      >
        <schedule-card mode="dates" :lesson-type="lessonType" />
      </ion-item>
    </template>
  </ion-list>
</template>

<script setup>
import {
  IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonNote, IonRow,
} from '@ionic/vue'
import { computed } from 'vue'
import { golfOutline } from 'ionicons/icons'

import { formatPricing, lessonTypePackages } from '@/6_shared/utils/formattingLessons'

import ScheduleCard from '@/views/instructors/schedules/ScheduleCard.vue'

const props = defineProps({
  lessonType: { required: true, type: Object },
  editable: { default: true, type: Boolean },
})

const emit = defineEmits(['edit:lesson'])

const lessonType = props.lessonType

function editLessonType(subPage) {
  emit('edit:lesson', subPage)
}

const packages = computed(() => {
  return lessonTypePackages(props.lessonType)
})

</script>
