<template>
  <ion-accordion-group v-if="events.length" value="">
    <!-- empty value to hide Activity by default -->
    <ion-accordion value="activity">
      <ion-item slot="header" color="light">
        <ion-label>Activity</ion-label>
      </ion-item>
      <div slot="content" class="ion-padding">
        <ul data-testid="lesson-events">
          <li v-for="event in events" :key="event.id">
            {{ event.type }} <span v-if="event.createdAt">- {{ useDateFormat(event.createdAt*1000, 'MMM D, h:mm a').value }}</span>
            <ion-note v-if="event.reason">{{ event.reason }}</ion-note>
            <ion-note v-if="event.changes" data-testid="lesson-event-reschedule" :data-changes="JSON.stringify(event.changes)">new timeslot: {{ event.changes.new.formattedDate }}, {{ event.changes.new.time }}</ion-note>
            <div style="display:none">
              <span v-if="event.cancellationRequestId" data-testid="cancellation-request-id">{{ event.cancellationRequestId }}</span>
            </div>
          </li>
        </ul>
      </div>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script setup>
import { IonAccordion, IonAccordionGroup, IonItem, IonLabel, IonNote } from '@ionic/vue'
import { computed } from 'vue'
import { useCollection } from 'vuefire'
import { useDateFormat } from '@vueuse/core'

import { LessonsRepository } from '@/data/LessonsRepository'

const props = defineProps({
  lesson: { type: Object, required: false, default: () => ({}) },
  invite: { type: Object, required: false, default: () => ({}) },
})

let events

if (props.lesson.id) {
  const lessonsRepo = new LessonsRepository()
  const localRef = computed(() => lessonsRepo.getEventsRef(props.lesson.id))
  const { data: eventsCollection } = useCollection(localRef, { ssrKey: 'lesson-events' })

  events = computed(() => {
    const result = [
      ...(eventsCollection.value || []),
      ...(props.lesson.logs || []),
    ]
    result.forEach((log) => {
      if (log.createdAt?.seconds) log.createdAt = log.createdAt?.seconds
    })
    return result
  })
}

if (props.invite.id) {
  events = computed(() => props.invite.logs)
}

</script>
