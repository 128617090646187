<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          Book Package
        </ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{name: 'students'}" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" class="ion-padding" data-testid="instructor-new-lesson-page">
      <error-message
        v-if="!packages.length"
        title="No Packages to Add"
        content="Please configure your packages offering in the schedule first"
      />
      <div v-if="packages.length">
        <ion-list v-if="pckg && student && packages.length">
          <ion-item lines="full" data-testid="student-name">
            <ion-label style="text-transform: capitalize;">{{ student.name }}</ion-label>
          </ion-item>
          <package-selector
            :pckg="pckg"
            :packages="packages"
            @select-package="handlePackageSelect"
          />
          <overrides-block
            v-if="pckg.packageType"
            :pckg="pckg"
            @update-overrides="handleOverrides"
          />
        </ion-list>
        <ion-item lines="none" color="light">
          <ion-label>
            <ion-note style="white-space:normal">
              <ul class="ion-no-margin" style="padding-inline-start: 1em">
                <li><strong>{{ pckg.name }}</strong> offline lessons will be booked towards this package reducing the remaining balance of lessons</li>
                <li>Payment handled offline</li>
                <li>Student will be notified when package is created</li>
              </ul>
            </ion-note>
          </ion-label>
        </ion-item>
        <p v-if="v$.$errors.length" lines="none">
          <ion-text color="danger">
            <ul data-testid="error-messages">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <li v-for="err in v$.$errors" :key="err.$uid" v-html="err.$message" />
            </ul>
          </ion-text>
        </p>
        <timeslot-checkout
          v-if="!v$.$invalid"
          :key="debouncedKey"
          mode="package"
          :package="pckg"
          :good-to-go="true"
          :payment-methods="{offline: true}"
          :demo-mode="userStore.is.demoStudent"
          @payment-complete="createPackage"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonBackButton, IonButtons, IonContent, IonHeader, IonItem,
  IonLabel, IonList, IonNote, IonPage, IonText, IonTitle, IonToolbar,
} from '@ionic/vue'
import { computed, getCurrentInstance, ref, watchEffect } from 'vue'
import { helpers } from '@vuelidate/validators'
import { onIonViewDidLeave } from '@ionic/vue'
import { refThrottled } from '@vueuse/core'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'

import ErrorMessage from '@/3_widgets/ErrorMessage.vue'
import { Instructor } from '@/5_entities/instructors/Instructor'
import { LessonsRepository } from '@/data/LessonsRepository'
import OverridesBlock from './OverridesBlock.vue'
import PackageSelector from './PackageSelector.vue'
import TimeslotCheckout from '@/views/golfers/lessons/TimeslotCheckout.vue'
import { sendRequest } from '@/6_shared/utils/sendRequest'
import { trackEvent } from '@/globals'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const router = useRouter()

const props = defineProps({
  studentId: { type: String, required: true },
})
const student = userStore.instructor.fn.getStudent(props.studentId)
const instructor = new Instructor(userStore.userData())

const createInitialPackage = () => ({
  instructorId: instructor.id,
  instructor: {
    name: instructor.name,
    photoURL: instructor.photoURL,
  },
})

const pckg = ref(createInitialPackage())

const throttledPckgKey = refThrottled(pckg, 200)
const debouncedKey = computed(() => JSON.stringify(throttledPckgKey.value))

const packages = computed(() => instructor.packages())

watchEffect(() => {
  // because student is a computed, we need to wait for it to be resolved
  if (student.value) {
    pckg.value.studentId = student.value.id
    pckg.value.student = {
      id: student.value.id,
      name: student.value.name,
      photoURL: student.value.photoURL,
    }
  }
})

const rules = {
  packageType: {
    required: helpers.withMessage('Please select package', value => !!value),
  },
}

const v$ = useVuelidate(rules, pckg)
v$.value.$touch()

const handlePackageSelect = (selectedPackage) => {
  pckg.value.packageType = selectedPackage
  Object.assign(pckg.value, selectedPackage)
}

const handleOverrides = (newOverrides) => {
  Object.assign(pckg.value, newOverrides)
}

const createPackage = async (paymentInfo) => {
  pckg.value.paymentInfo = paymentInfo
  const LessonsRepo = new LessonsRepository()
  await sendRequest('Purchasing Package...', async () => {
    pckg.value.paymentInfo = paymentInfo
    await LessonsRepo.createPackage({ package: pckg.value })
    router.replace({ name: 'student', params: { studentId: props.studentId }})
    trackEvent('package_purchased', { lessons: pckg.value.lessons, price: pckg.value.price, name: pckg.value.name })
    alert('Successfully added package')
  })
}

onIonViewDidLeave(() => {
  const instance = getCurrentInstance()
  if (instance) instance.proxy.$destroy()
})
</script>
