// import { array, number, object, string } from 'yup'

export const ALL_PRICING_TYPES = {
  adult: { key: 'adult', name: 'Adult' },
  junior: { key: 'junior', name: 'Junior' },
  playing: { key: 'playing', name: 'Playing Lesson' },
  adult_2: { key: 'adult_2', name: 'Group of 2' },
  adult_3: { key: 'adult_3', name: 'Group of 3' },
  adult_4: { key: 'adult_4', name: 'Group of 4' },
  adult_5: { key: 'adult_5', name: 'Group of 5' },
  club_fitting: { key: 'club_fitting', name: 'Club Fitting' },
  fitness: { key: 'fitness', name: 'Fitness Session' },
  coaching: { key: 'coaching', name: 'Coaching' },
}

export const POSSIBLE_LESSON_LENGTHS = [30, 45, 60, 75, 90, 120]
