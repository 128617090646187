<template>
  <ion-button fill="clear" color="success" @click="presentActionSheet">
    <ion-icon slot="start" :icon="add" />
    Add Time
  </ion-button>
</template>

<script setup>
import {
  IonButton, IonIcon, actionSheetController,
} from '@ionic/vue'
import { add } from 'ionicons/icons'
import { computed } from 'vue'
import { useDateFormat } from '@vueuse/core'

const props = defineProps({
  day: {
    required: true,
    type: Object,
  },
  allDays: {
    required: true,
    type: Array,
  },
})

const emit = defineEmits(['addOption'])

const daysToCopyFrom = computed(() => {
  return props.allDays
    .filter(d => d.when !== props.day.when && d.times.length > 0)
    .sort((a, b) => a.when.localeCompare(b.when))
})

const presentActionSheet = async () => {
  if (daysToCopyFrom.value.length === 0) {
    emit('addOption', 'add', props.day, props.allDays)
    return
  } else {
    let buttons = []

    daysToCopyFrom.value.forEach((copyDay) => {
      // check if copyDay.when is a date in iso format (2024-01-01), then useDateFormat
      // otherwise use the same string
      const copyFromText = /^\d{4}-\d{2}-\d{2}$/.test(copyDay.when) ? useDateFormat(copyDay.when, 'MMM D ddd').value : copyDay.when

      buttons.push({
        // copyDay.when can be either date or string, so we need to check it
        text: `Copy from ${copyFromText}`,
        handler: () => {
          emit('addOption', copyDay.when, props.day, props.allDays)
        },
      })
    })

    buttons = buttons.reverse().slice(0, 7).reverse() // get 7 most recent dates

    buttons.push({ text: 'Cancel', role: 'cancel', data: { action: 'cancel' }})
    buttons.push({
      text: '+ Add Start Time',
      handler: () => {
        emit('addOption', 'add', props.day, props.allDays)
      },
    })

    const actionSheet = await actionSheetController.create({
      buttons: buttons,
    })

    actionSheet.present()
  }
}

</script>
