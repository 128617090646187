/* global Stripe */
import { STRIPE_PUBLIC_KEY, STRIPE_TEST_PUBLIC_KEY } from '@/globals'

export function usePaymentCheckout({
  demoMode,
  selectedPaymentMethod,
  onComplete,
  getSessionData,
  stripeSession,
}) {
  let checkout = null

  function cleanUpCheckout() {
    if (checkout) {
      checkout.destroy()
      checkout = null
    }
    const checkoutElement = document.getElementById('checkout')
    if (checkoutElement) checkoutElement.innerHTML = ''
  }

  async function initializeCheckout(paymentMethod) {
    cleanUpCheckout()

    const stripe = Stripe(demoMode ? STRIPE_TEST_PUBLIC_KEY : STRIPE_PUBLIC_KEY)

    try {
      if (paymentMethod !== selectedPaymentMethod.value) {
        throw new Error('Payment method changed')
      }

      const sessionData = await getSessionData()
      stripeSession.value = sessionData
      const clientSecret = sessionData.clientSecret
      if (clientSecret === 'offline') return
      checkout = await stripe.initEmbeddedCheckout({
        fetchClientSecret: async () => clientSecret,
        onComplete,
      })

      const checkoutElement = document.getElementById('checkout')
      if (checkoutElement) {
        checkoutElement.innerHTML = ''
        checkout.mount('#checkout')
      }
    } catch (error) {
      console.error('Failed to initialize checkout:', error)
      cleanUpCheckout()
    }
  }

  return {
    initializeCheckout,
    cleanUpCheckout,
  }
}
