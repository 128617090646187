<template>
  <ion-input
    v-maskito="phoneOptions"
    :model-value="modelValue"
    type="tel"
    :label="label"
    label-placement="stacked"
    placeholder="+1 (xxx) xxx-xxxx"
    :error-text="errorText"
    :helper-text="helperText"
    :class="inputClass"
    @ion-input="$emit('update:modelValue', $event.target.value)"
    @ion-change="$emit('change', $event)"
  />
</template>

<script setup>
import { IonInput } from '@ionic/vue'
import { maskito as vMaskito } from '@maskito/vue'

defineProps({
  modelValue: {
    type: String,
    default: '',
    required: true,
  },
  label: {
    type: String,
    default: 'Phone Number',
  },
  errorText: {
    type: String,
    default: '',
  },
  helperText: {
    type: String,
    default: '',
  },
  inputClass: {
    type: [String, Object],
    default: '',
  },
})

defineEmits(['update:modelValue', 'change'])

const phoneOptions = {
  mask: ['+', '1', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  elementPredicate: (el) => {
    return new Promise((resolve) => {
      requestAnimationFrame(async () => {
        const input = await el.getInputElement()
        resolve(input)
      })
    })
  },
}
</script>
