<template>
  <ion-col style="text-align: right;">
    <template v-for="startTime in day.times" :key="startTime">
      <ion-chip :data-testid="startTime" @click="removeOpening(startTime)">
        <ion-label>{{ displayFullLength(startTime, lessonType.length) }}</ion-label>
        <ion-icon :icon="close" />
      </ion-chip>
      <br>
    </template>
    <schedule-add-time-button
      :day="day" :all-days="allDays" @add-option="addOption"
    />
  </ion-col>
</template>

<script setup>
import { IonChip, IonCol, IonIcon, IonLabel } from '@ionic/vue'
import { close } from 'ionicons/icons'

import { displayFullLength } from '@/6_shared/utils/formattingLessons'

import ScheduleAddTimeButton from '@/views/instructors/schedules/ScheduleAddTimeButton.vue'

defineProps({
  day: {
    required: true,
    type: Object,
  },
  allDays: {
    required: true,
    type: Array,
  },
  lessonType: {
    required: true,
    type: Object,
  },
})

const emit = defineEmits(['showTimePicker', 'copyDayTimes', 'removeOpening'])

function addOption(option) {
  if (option === 'add') {
    emit('showTimePicker')
  } else {
    emit('copyDayTimes', option)
  }
}

function removeOpening(timeToRemove) {
  emit('removeOpening', timeToRemove)
}

</script>
