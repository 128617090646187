<template>
  <ion-item
    detail="true" data-testid="invite-item" lines="full"
    button="true" :router-link="{ name: 'invite', params: { inviteId: invite.id } }"
  >
    <user-avatar
      v-if="displayUser" slot="start" :url="invite[otherPartyName].photoURL"
      :name="invite[otherPartyName].name"
    />
    <div v-if="!displayUser" slot="start">
      <ion-chip :outline="true">
        <ion-icon :icon="handRightOutline" color="primary" />
      </ion-chip>
    </div>
    <ion-label>
      <span v-if="displayUser">{{ invite[otherPartyName].name }}</span>
      <p>{{ invite.formattedDate }}, {{ displayFullLength(invite.time, invite.length) }}</p>
      <ion-chip v-if="displayUser" :outline="true">
        <ion-icon :icon="handRightOutline" color="primary" />
        Lesson Invite, ${{ invite.pricing.price }}
      </ion-chip>
      <ion-note v-if="!displayUser" color="danger">Lesson Invite, ${{ invite.pricing.price }}</ion-note>
    </ion-label>
    <ion-icon
      v-if="userStore.is.student" slot="end" :icon="ellipse"
      data-testid="unread-icon"
      style="color: #1982FC; fill: #1982FC" size="small"
    />
  </ion-item>
</template>

<script setup>
import { IonChip, IonIcon, IonItem, IonLabel, IonNote } from '@ionic/vue'
import { ellipse } from 'ionicons/icons'
import { handRightOutline } from 'ionicons/icons'

import UserAvatar from '@/3_widgets/UserAvatar.vue'
import { displayFullLength } from '@/6_shared/utils/formattingLessons'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

defineProps({
  invite: { type: Object, required: true },
  displayUser: { type: Boolean, default: true },
})

const otherPartyName = userStore.is.student ? 'instructor' : 'student'
</script>

<style scoped>
ion-chip {
  --color: black;
  border-width: 0;
  margin-inline: 0;
  padding-inline: 0;
}
ion-chip ion-icon {
  margin-inline-start: 0px;
  margin-inline-end: 2px;
}
</style>
