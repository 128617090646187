<template>
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-card>
          <ion-card-header>
            <ion-card-title>Overview</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-list>
              <ion-item>
                <ion-label>Total Completed Lessons</ion-label>
                <span slot="end">{{ lessons.length }}</span>
              </ion-item>
              <ion-item>
                <ion-label>Total Earnings From Lessons</ion-label>
                <span slot="end">
                  {{ formatMoney(totalEarnings) }}
                </span>
              </ion-item>
              <ion-item>
                <ion-label>Total Earnings from Packages</ion-label>
                <span slot="end">{{ formatMoney(packagesEarnings) }}</span>
              </ion-item>
              <ion-item>
                <ion-label><strong>Saved</strong> on Processing Fees</ion-label>
                <strong slot="end" style="color: var(--ion-color-success);">{{ formatMoney(processingFeeSavings) }}</strong>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12" size-md="6">
        <ion-card>
          <ion-card-header>
            <ion-card-title>Payment Methods</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-list>
              <ion-item>
                <ion-label>Online Payments</ion-label>
                <span slot="end">
                  {{ paymentMethodCount('creditCard') }}
                  ({{ paymentMethodPercentages.online }}%)
                </span>
              </ion-item>
              <ion-item>
                <ion-label>Offline Payments</ion-label>
                <span slot="end">
                  {{ paymentMethodCount('offline') }}
                  ({{ paymentMethodPercentages.offline }}%)
                </span>
              </ion-item>
              <ion-item>
                <ion-label>Package Lessons</ion-label>
                <span slot="end">
                  {{ packageLessonsCount }}
                  ({{ paymentMethodPercentages.package }}%)
                </span>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>

      <ion-col size="12" size-md="6">
        <ion-card>
          <ion-card-header>
            <ion-card-title>Payment Status</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-list>
              <ion-item>
                <ion-label>Paid Lessons</ion-label>
                <span slot="end">{{ paymentStatusCount('paid') }}</span>
              </ion-item>
              <ion-item>
                <ion-label>Unpaid Lessons</ion-label>
                <span slot="end">{{ paymentStatusCount('authorized') }}</span>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-card>
          <ion-card-header>
            <ion-card-title>Package Statistics</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-list>
              <ion-item>
                <ion-label>Current Packages</ion-label>
                <span slot="end">{{ activePackagesCount }}</span>
              </ion-item>
              <ion-item>
                <ion-label>Completed Packages</ion-label>
                <span slot="end">{{ purchasedPackages.length - activePackagesCount }}</span>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup>
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonItem, IonLabel, IonList, IonRow } from '@ionic/vue'
import { computed } from 'vue'

import { LessonsRepository } from '@/data/LessonsRepository'
import { formatMoney } from '@/shared/utils/formatMoney'

const props = defineProps({
  lessons: {
    type: Array,
    required: true,
  },
})

const packageLessonsCount = computed(() => {
  return props.lessons.filter(lesson => lesson.partOfPackage()).length
})

function paymentMethodCount(method) {
  return props.lessons.filter(lesson =>
    !lesson.partOfPackage() && lesson.paymentInfoObject().method === method,
  ).length
}

function paymentStatusCount(status) {
  return props.lessons.filter((lesson) => {
    if (status === 'paid') {
      return lesson.partOfPackage() || lesson.paymentInfoObject().status === status
    } else {
      return !lesson.partOfPackage() && lesson.paymentInfoObject().status === status
    }
  }).length
}

const totalEarnings = computed(() => {
  if (!props.lessons?.length) return 0
  return props.lessons.reduce((total, lesson) => {
    if (lesson.partOfPackage()) {
      return total
    }
    return total + (lesson.paymentInfoObject()?.price || 0)
  }, 0).toFixed(2)
})

const processingFeeSavings = computed(() => {
  const fromLessons = parseFloat(totalEarnings.value) * 0.029 + props.lessons.length * 0.30
  const fromPackages = parseFloat(packagesEarnings.value) * 0.029 + (purchasedPackages.value.length * 0.30)
  return (fromLessons + fromPackages).toFixed(2)
})

const paymentMethodPercentages = computed(() => ({
  online: Math.round(paymentMethodCount('creditCard') / props.lessons.length * 100 || 0),
  offline: Math.round(paymentMethodCount('offline') / props.lessons.length * 100 || 0),
  package: Math.round(packageLessonsCount.value / props.lessons.length * 100 || 0),
}))

const LessonsRepo = new LessonsRepository()
const purchasedPackages = LessonsRepo.getLessonPackages()
const activePackagesCount = computed(() => {
  return purchasedPackages.value.filter(pckg => pckg.lessonsLeft > 0).length
})

const packagesEarnings = computed(() => {
  return purchasedPackages.value.reduce((total, pckg) => {
    return total + (parseFloat(pckg.paymentInfo?.metadata?.packagePrice || pckg.price) || 0)
  }, 0).toFixed(2)
})
</script>

<style scoped>
ion-note {
  display: flex;
  align-items: center;
}
</style>
