import {
  listTimeslots,
} from '@/firebase'

export class TimeslotsRepository {
  constructor() {

  }

  async getTimeslots(instructorId, pricingType) {
    return await listTimeslots({ instructorId, pricingType })
  }
}
