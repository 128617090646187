<template>
  <ion-page data-testid="instructor-new-lesson-page">
    <ion-header>
      <ion-toolbar>
        <ion-title v-if="lesson">
          <span v-if="lesson.offlineStudent()">Book Lesson</span>
          <span v-else>Lesson Invite</span>
        </ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{name: 'students'}" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" class="ion-padding" data-testid="instructor-new-lesson-page">
      <error-message
        v-if="!instructor.lessonTypes().length"
        title="No Schedules Configured"
        content="Please go to the schedule page to configure your lesson types"
      />
      <div v-if="instructor.lessonTypes().length">
        <ion-list v-if="lesson && student">
          <ion-item lines="full" data-testid="student-name">
            <ion-label style="text-transform: capitalize;">{{ student.name }}</ion-label>
          </ion-item>
          <lesson-type-selector
            :lesson="lesson"
            :lesson-types="instructor.lessonTypes()"
            @select-lesson-type="handleLessonTypeSelect"
          />
          <timeslot-selector
            :lesson="lesson" :instructor="instructor" :is-valid="!v$.date.$invalid"
            @select-timeslot="handleSelectTimeslot"
          />
          <overrides-block v-if="lesson.lessonType" :lesson="lesson" @update-overrides="handleOverrides" />
          <ion-item color="light" lines="none">
            <ion-label>
              <ion-note v-if="!lesson.offlineStudent()" style="white-space:normal">
                <ul class="ion-no-margin" style="padding-inline-start: 1em">
                  <li>Student will be notified about the lesson</li>
                  <li>Student selects their preferred payment method</li>
                  <li>Time slot remains available until student responds</li>
                </ul>
              </ion-note>
              <ion-note v-if="lesson.offlineStudent()" style="white-space:normal">
                <ul class="ion-no-margin" style="padding-inline-start: 1em">
                  <li>No action required from student</li>
                  <li>Payment processed offline</li>
                  <li>Lesson is immediately scheduled</li>
                </ul>
              </ion-note>
            </ion-label>
          </ion-item>
        </ion-list>
        <p v-if="v$.$errors.length" lines="none">
          <ion-text color="danger">
            <ul data-testid="error-messages">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <li v-for="err in v$.$errors" :key="err.$uid" v-html="err.$message" />
            </ul>
          </ion-text>
        </p>
        <div v-if="!v$.$invalid && !lesson.offlineStudent()">
          <ion-button
            fill="solid" color="success" expand="block"
            @click="createInvite"
          >
            Create Lesson Invite
          </ion-button>
        </div>
        <timeslot-checkout
          v-if="!v$.$invalid && lesson.offlineStudent()"
          :key="debouncedKey"
          mode="timeslot"
          :timeslot="lesson"
          :good-to-go="true"
          :payment-methods="{offline: true}"
          :demo-mode="userStore.is.demoStudent"
          @payment-complete="createLesson"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonItem,
  IonLabel, IonList, IonNote, IonPage, IonText, IonTitle, IonToolbar,
} from '@ionic/vue'
import { computed, getCurrentInstance, ref, watchEffect } from 'vue'
import { helpers } from '@vuelidate/validators'
import { onIonViewDidLeave } from '@ionic/vue'
import { refThrottled } from '@vueuse/core'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'

import { LessonExport as Lesson, LessonsRepository } from '@/data/LessonsRepository'
import ErrorMessage from '@/3_widgets/ErrorMessage.vue'
import { Instructor } from '@/5_entities/instructors/Instructor'
import { LessonInvitesRepository } from '@/data/LessonInvite'
import LessonTypeSelector from './LessonTypeSelector.vue'
import OverridesBlock from './OverridesBlock.vue'
import TimeslotCheckout from '@/views/golfers/lessons/TimeslotCheckout.vue'
import TimeslotSelector from './TimeslotSelector.vue'
import { sendRequest } from '@/6_shared/utils/sendRequest'
import { trackEvent } from '@/globals'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const LessonsRepo = new LessonsRepository()
const router = useRouter()

const props = defineProps({
  studentId: { type: String, required: true },
})
const student = userStore.instructor.fn.getStudent(props.studentId)
const instructor = new Instructor(userStore.userData())

const createInitialLesson = () => new Lesson({
  instructorId: instructor.id,
  instructor: {
    name: instructor.name,
    photoURL: instructor.photoURL,
  },
})

const lesson = ref(createInitialLesson())

const throttledLessonKey = refThrottled(lesson, 200)
const debouncedKey = computed(() => JSON.stringify(throttledLessonKey.value))

watchEffect(() => {
  // because student is a computed, we need to wait for it to be resolved
  if (student.value) {
    lesson.value.studentId = student.value.id
    lesson.value.student = {
      name: student.value.name,
      photoURL: student.value.photoURL,
    }
  }
})

const rules = {
  date: {
    required: helpers.withMessage('Please select Date & Time', value => !!value),
  },
  lessonType: {
    required: helpers.withMessage('Please select a lesson type', value => !!value),
  },
}

const v$ = useVuelidate(rules, lesson)
v$.value.$touch()

const handleLessonTypeSelect = (selectedLessonType) => {
  lesson.value.lessonType = selectedLessonType.lessonType
  lesson.value.pricing = selectedLessonType.pricing
  lesson.value.length = selectedLessonType.lessonType.length
  lesson.value.description = selectedLessonType.lessonType.description
}

const handleSelectTimeslot = (timeslot) => {
  lesson.value.instructorId = instructor.id
  lesson.value.instructor = {
    name: instructor.name,
    photoURL: instructor.photoURL,
  }
  delete timeslot.pricing // don't override pricing with default timeslot pricing
  delete timeslot.length // don't override length with default timeslot length
  Object.assign(lesson.value, timeslot)
}

const handleOverrides = (newOverrides) => {
  lesson.value.pricing = newOverrides.pricing
  lesson.value.length = newOverrides.length
  lesson.value.description = newOverrides.description
}

const createLesson = async (paymentInfo) => {
  lesson.value.paymentInfo = paymentInfo
  await sendRequest('Creating Lesson...', async () => {
    const result = (await LessonsRepo.createLessonAndAuthorizePayment(lesson.value)).data
    if (result.status !== 'success') {
      alert(result.error)
    } else {
      router.replace({ name: 'lessonPage', params: { lessonId: result.lesson.id }})
      trackEvent('offline_lesson_booked')
    }
  }, lesson)
}

const createInvite = async () => {
  const lessonInvitesRepo = new LessonInvitesRepository()
  const invite = lesson.value.toFirestore()
  await sendRequest('Creating Lesson Invite...', async () => {
    const result = (await lessonInvitesRepo.createInvite(invite)).data
    if (result.status !== 'success') {
      alert(result.error)
    } else {
      router.replace({ name: 'invite', params: { inviteId: result.invite.id }})
      trackEvent('offline_lesson_booked')
    }
  }, lesson)
}

onIonViewDidLeave(() => {
  const instance = getCurrentInstance()
  if (instance) instance.proxy.$destroy()
})
</script>
