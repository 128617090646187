<template>
  <ion-header v-show="route.meta.title">
    <ion-toolbar>
      <ion-buttons slot="start" :router-link="{name: 'index'}">
        <img height="33" alt="" src="@/assets/logos/logo.svg">
      </ion-buttons>
      <ion-title>
        {{ route.meta?.title }}
      </ion-title>
      <ion-buttons slot="end">
        <ion-button id="intercom_custom_launcher">
          <ion-icon slot="icon-only" :icon="helpCircleOutline" />
        </ion-button>
        <ChatButton v-if="currentUser" />
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script setup>
import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/vue'
import { useCurrentUser } from 'vuefire'
import { watchEffect } from 'vue'

import ChatButton from '@/shared/components/Header/ChatButton.vue'
import { helpCircleOutline } from 'ionicons/icons'
import { useRoute } from 'vue-router'
import { useTitle } from '@vueuse/core'

const currentUser = useCurrentUser()
const route = useRoute()

watchEffect(() => {
  useTitle(route.meta.title)
})
</script>

<style scoped>
ion-title {
  padding-inline-end: 12px;
  text-align: left;
}
ion-title.md {
  padding-inline-start: 5px;
}
ion-title.ios {
  padding-inline-start: 45px;
}
</style>
