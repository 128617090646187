<template>
  <div
    data-testid="sign-in-block"
    class="ion-padding" style="background-color: var(--ion-color-light); width: 100%;"
  >
    <p class="ion-text-center ion-no-margin ion-margin-bottom">
      Sign up now to start your journey to better golf, whether you're a beginner or pro.
    </p>

    <ion-button
      fill="outline" expand="block" color="dark"
      class="ion-margin-bottom" @click="signIn(new FacebookAuthProvider())"
    >
      <ion-icon slot="start" :icon="logoFacebook" />
      <ion-label>Facebook</ion-label>
    </ion-button>

    <!-- <ion-button
      fill="outline" expand="block" color="dark"
      class="ion-margin-bottom" @click="signIn(new GoogleAuthProvider())"
    >
      <ion-icon slot="start" :icon="logoGoogle" />
      <ion-label>Google</ion-label>
    </ion-button> -->

    <email-modal @login-success="handleSignInSuccess" />

    <p class="ion-text-center ion-no-margin" style="line-height: 1.5em">
      By joining, you agree to our
      <br>
      <a href="#" @click.prevent="tosModalRef.$el.present()">Terms of Service</a> &nbsp;&&nbsp;
      <a href="/docs/privacy_policy_v2.pdf" target="_blank">Privacy Policy</a>
    </p>
    <ion-modal ref="tosModalRef">
      <tos-modal @dismiss="tosModalRef.$el.dismiss()" />
    </ion-modal>
  </div>
</template>

<script setup>
import { FacebookAuthProvider, signInWithPopup } from 'firebase/auth'
import { IonButton, IonIcon, IonLabel, IonModal } from '@ionic/vue'
import { getCurrentUser, useDocument, useFirebaseAuth, usePendingPromises } from 'vuefire'
import { useRoute, useRouter } from 'vue-router'
import { logoFacebook } from 'ionicons/icons'
import { ref } from 'vue'

import EmailModal from './EmailModal.vue'
import TosModal from '@/3_widgets/timeslots/TosModal.vue'
import { useUserStore } from '@/stores/user'

const vueRouter = useRouter()
const route = useRoute()
const auth = useFirebaseAuth()
const _userFromStore = useUserStore()
const userDoc = ref(null)
const tosModalRef = ref()

async function handleSignInSuccess() {
  await getCurrentUser()
  const { promise } = useDocument(_userFromStore.userDocRef)
  await usePendingPromises()
  userDoc.value = await promise.value

  if (route.query.redirect && userDoc.value?.type) {
    window.location.href = route.query.redirect
  } else {
    const path = vueRouter.getRoutes().find((route) => {
      const routeName = userDoc.value?.type ? 'insideHome' : 'account'
      return route.name === routeName
    }).path
    window.location.href = path
  }
}

const signIn = async (provider) => {
  try {
    await signInWithPopup(auth, provider)
    await handleSignInSuccess()
  } catch (error) {
    console.error('Failed sign', error)
    if (error.code === 'auth/account-exists-with-different-credential') {
      setTimeout(() => alert('You have already used Email or Google auth for this email. Please use that method.'), 1)
    }
  }
}
</script>

<style>
/* CAN NOT BE SCOPED */
ion-modal#login-modal {
  /* --width: 90%; */
  /* --min-width: 250px; */
  /* --min-height: 500px; */
  --height: fit-content;
  /* --border-radius: 14px; */
}
</style>
