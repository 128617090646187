<template>
  <ion-page data-testid="tos-modal">
    <ion-header>
      <ion-toolbar>
        <ion-title>
          Terms of Use
        </ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="true" @click="cancel" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" class="ion-padding">
      <h1>Terms of Use</h1>

      <p>This is a short version of our Terms of Use. The full version can be viewed at this <a href="/docs/tos_v2.pdf" target="_blank">link</a>. This short version is provided for your convenience, but the full version takes precedence.</p>

      <h2>Instructor Policy</h2>
      <p>Instructors are treated as independent businesses. SwingMatch is not responsible for Instructor behavior or disputes. Lessons must be accepted within 96 hours or 24 hours before the lesson.</p>

      <h2>Golfer Policy</h2>
      <p>Golfers must adhere to facility rules. Lesson requests must be made 36 hours in advance. Payments are instant, and refunds are subject to cancellation policies.</p>

      <h2>SwingMatch Accounts</h2>
      <p>Accounts violating Terms & Conditions can be restricted or deleted at SwingMatch’s discretion.</p>

      <h2>Lesson Cancellation Policy & Refunds</h2>
      <ul>
        <li>No refund for Golfer no-shows or cancellations within 24 hours of the lesson.</li>
        <li>Refunds for cancellations more than 24 hours in advance, excluding Stripe fees.</li>
        <li>Refunds if the Instructor cancels or does not accept the lesson within 7 days.</li>
      </ul>

      <h2>Weather Policy</h2>
      <p>The facility where the lesson is scheduled will decide on cancellations due to inclement weather. If there is no policy at the location, the Golfer and Instructor can decide to cancel or reschedule. If canceled, a refund will be issued to the Golfer.</p>

      <h2>Late Arrivals</h2>
      <p>Both parties should arrive on time. Disputes are reviewed case-by-case.</p>

      <h2>Fee Structure</h2>
      <p>Instructors set lesson prices. Transaction fees cover Stripe's and SwingMatch’s fees.</p>

      <h2>Privacy</h2>
      <p>We use your email to verify your identity and contact you. Your personal information will not be shared with third parties without your consent.</p>

      <h2>Payments</h2>
      <p>All payments are handled by Stripe, and by using SwingMatch, you agree to <a href="https://stripe.com/en-ca/legal/ssa" target="_blank">Stripe's Terms and Conditions</a>.</p>

      <h2>Limitation of Liability</h2>
      <p>SwingMatch is not responsible for injuries during lessons. SwingMatch Golf is not liable for any damages or losses resulting from your use of the site.</p>

      <h2>Contact</h2>
      <p>For questions, email <a href="mailto:swingmatch.info@gmail.com">swingmatch.info@gmail.com</a>.</p>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonBackButton, IonButtons, IonContent, IonHeader, IonPage,
  IonTitle, IonToolbar,
} from '@ionic/vue'

const emit = defineEmits(['dismiss'])

function cancel() {
  emit('dismiss')
}
</script>

<style scoped>
h2 {
  padding-bottom: 0;
  margin-bottom: 0;
  font-weight: normal;
}
p {
  margin-top: 0.3em;
  margin-bottom: 1.7em;
}
</style>
