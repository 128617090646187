<template>
  <ion-accordion-group v-if="isInstructor || props.lesson.instructorNotes" data-testid="instructor-notes" :value="isOpen ? 'instructor-notes' : null">
    <ion-accordion value="instructor-notes">
      <ion-item slot="header" color="light">
        Lesson Notes
      </ion-item>
      <div slot="content" class="ion-padding">
        <template v-if="isInstructor && isEditing">
          <ion-textarea
            v-model="notes" :label="`Notes for ${lesson.student?.name}`"
            placeholder="Add notes about this lesson. You can use:
- Bold: **text**
- Italic: *text*
- Lists: Start lines with - or *
- Paragraphs: Double space between them"
            auto-grow="true" label-placement="stacked" rows="8"
            mode="md" fill="outline" counter="true"
            maxlength="2500" :disabled="isSaving"
          />
          <ion-button
            expand="block" fill="outline" color="success"
            size="small"
            :disabled="!hasChanges || isSaving"
            @click="saveNotes"
          >
            <ion-spinner v-if="isSaving" name="crescent" />
            <span v-else>Save Notes</span>
          </ion-button>
        </template>
        <template v-else>
          <blockquote v-if="lesson.instructorNotes?.length > 0" class="instructor-note">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="formatText(lesson.instructorNotes)" />
            <cite>- {{ lesson.instructor?.name }}, {{ formatDate(lesson.notesUpdatedAt.seconds * 1000, 'MMM dd, h:mm a') }}</cite>
          </blockquote>
          <em v-else-if="isInstructor">No notes yet</em>

          <ion-button
            v-if="isInstructor"
            expand="block" fill="outline"
            size="small"
            @click="isEditing = true"
          >
            Edit Notes
          </ion-button>
        </template>
      </div>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script setup>
import { IonAccordion, IonAccordionGroup, IonButton, IonItem, IonSpinner, IonTextarea } from '@ionic/vue'
import { computed, ref } from 'vue'
import { formatDate } from 'date-fns'

import { formatText } from '@/6_shared/utils/formatText'

const props = defineProps({
  lesson: { type: Object, required: true },
  currentUserType: { type: String, required: true, default: 'student' },
})

const emit = defineEmits(['update:notes'])

const isInstructor = computed(() => props.currentUserType === 'instructor')
const notes = ref(props.lesson.instructorNotes || '')
const hasChanges = computed(() => notes.value !== props.lesson.instructorNotes)
const isSaving = ref(false)
const isOpen = props.lesson.instructorNotes?.length > 0
const isEditing = ref(!props.lesson.instructorNotes)

async function saveNotes() {
  isSaving.value = true
  try {
    await emit('update:notes', notes.value)
    isEditing.value = false
  } finally {
    isSaving.value = false
  }
}
</script>

<style scoped>
.instructor-note {
  background: #f5f5f5;
  border-left: 4px solid var(--ion-color-primary);
  margin: 0;
  padding: 1em;
}

:deep(.instructor-note div p:first-child) {
  margin-top: 0;
}

.instructor-note cite {
  display: block;
  margin-top: 0.5em;
  font-size: 0.9em;
  color: var(--ion-color-medium);
}
</style>
