<template>
  <ion-accordion-group v-if="pckg.packageType">
    <ion-accordion value="overrides">
      <ion-item slot="header" data-testid="overrides-block-accordion">
        <ion-label>
          <ion-note color="dark"><strong>Customize price and number of lessons</strong></ion-note>
        </ion-label>
      </ion-item>
      <div slot="content">
        <ion-item
          data-testid="custom-price-block" lines="none"
          style="overflow:visible" class="ion-margin-bottom ion-margin-top"
        >
          <ion-input
            v-model.number="overrides.price" type="number" mode="md"
            fill="outline" label="Price" label-placement="stacked"
            debounce="200" :class="{'ion-invalid ion-touched': v$.price.$invalid && v$.price.$dirty}"
          >
            <span slot="start">$</span>
          </ion-input>
        </ion-item>

        <ion-item
          data-testid="custom-lessons-block" lines="none"
          style="overflow:visible" class="ion-margin-bottom ion-margin-top"
        >
          <ion-input
            v-model.number="overrides.lessons" type="number" mode="md"
            fill="outline" label="Number of Lessons" label-placement="stacked"
            debounce="200" :class="{'ion-invalid ion-touched': v$.lessons.$invalid && v$.lessons.$dirty}"
          />
        </ion-item>
      </div>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script setup>
import { IonAccordion, IonAccordionGroup, IonInput, IonItem, IonLabel, IonNote } from '@ionic/vue'
import { ref, watchEffect } from 'vue'
import { helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

const props = defineProps({
  pckg: { type: Object, required: true },
})

const emit = defineEmits(['updateOverrides'])

const overrides = ref({})

// Update overrides when lesson values change
watchEffect(() => {
  overrides.value = {
    price: props.pckg.price,
    lessons: props.pckg.lessons,
  }
})

// Emit changes to parent when overrides change
watchEffect(() => {
  emit('updateOverrides', overrides.value)
})

const rules = {
  price: {
    required: helpers.withMessage('Please enter price', value => !!value),
  },
  lessons: {
    required: helpers.withMessage('Please enter number of lessons', value => !!value),
  },
}

const v$ = useVuelidate(rules, overrides)
v$.value.$touch()
</script>
