<template>
  <div v-if="SM.env === 'development'" style="position: absolute; bottom: -15px; right: 30px">
    <ion-button size="small" color="warning" @click="testLogin('TEST_INSTRUCTOR_0')">
      TEST_INS
    </ion-button>
    <ion-button size="small" color="warning" @click="testLogin('TEST_STUDENT_0')">
      TEST_STU
    </ion-button>
    <ion-button size="small" color="warning" @click="impersonate">
      impersonate
    </ion-button>
  </div>
</template>

<script setup>
import { IonButton } from '@ionic/vue'
import { SM } from '@/globals'

const emit = defineEmits(['test-login'])

async function testLogin(uid) {
  emit('test-login', `${uid}@example.com`, '123456', { uid })
}

async function impersonate() {
  const email = window.prompt('Enter the user Email to impersonate', 'cdotto@ualberta.ca')
  if (email) {
    emit('test-login', email, '123456', { email: `IMPERSONATE${email}` })
  }
}
</script>
