import { addDoc, collection, doc, limit,
  or, query, serverTimestamp, updateDoc, where,
} from 'firebase/firestore'
import { firestoreDefaultConverter, useCollection, useDocument, useFirestore } from 'vuefire'

import { answerRescheduleRequest, cancelLesson,
  confirmLessonByInstructor, createLessonAndAuthorizePayment, createPackage, declineLessonByInstructor,
  requestReschedule, rescheduleOfflineLesson, sendPaymentReminder, updateLessonNotes, updateOfflinePaymentStatus,
} from '@/firebase'

const { Lesson } = require('@functions/_shared_src/Lesson.js')

export const LessonExport = Lesson

import { trackEvent } from '@/globals'
import { useUserStore } from '@/stores/user'

const db = useFirestore()

const LessonsConverter = {
  toFirestore: lesson => lesson.toFirestore(),
  fromFirestore: (snapshot, options) => {
    const data = firestoreDefaultConverter.fromFirestore(snapshot, options)
    return new Lesson(data)
  },
}

export class LessonsRepository {
  constructor() {
    this.lessonsCollection = collection(db, 'lessons')
    this.userStore = useUserStore()
  }

  async createLessonAndAuthorizePayment(lesson) {
    lesson = new Lesson(lesson)
    return createLessonAndAuthorizePayment(lesson.toFirestore())
  }

  getRef(lessonId) {
    return doc(this.lessonsCollection, lessonId).withConverter(LessonsConverter)
  }

  async update(lesson) {
    await updateDoc(this.getRef(lesson.id), lesson.toFirestore())
  }

  async decline(lesson, declineReason) {
    trackEvent('lesson_declined', { reason: declineReason })
    await declineLessonByInstructor({ lesson, declineReason })
  }

  async confirm(lesson) {
    trackEvent('lesson_confirmed')
    return await confirmLessonByInstructor(lesson)
  }

  async requestReschedule({ lesson }) {
    trackEvent('lesson_reschedule_requested')
    await requestReschedule({ lesson })
  }

  async answerRescheduleRequest(lesson, approved) {
    trackEvent('lesson_reschedule_answered', { approved })
    await answerRescheduleRequest({ lesson, approved })
  }

  async rescheduleOfflineLesson({ lesson }) {
    trackEvent('lesson_reschedule_offline')
    await rescheduleOfflineLesson({ lesson })
  }

  async cancelLesson(lesson, cancellationReason) {
    trackEvent('lesson_cancelled', { reason: cancellationReason })
    await cancelLesson({ lesson, cancellationReason })
  }

  async sendPaymentReminder({ lesson, pckg }) {
    await sendPaymentReminder({ lesson, pckg })
  }

  async updateOfflinePaymentStatus({ lesson, pckg, paymentStatus }) {
    await updateOfflinePaymentStatus({ lesson, pckg, paymentStatus })
  }

  get(lessonId) {
    return useDocument(this.getRef(lessonId))
  }

  getUserLessons() {
    const userLessonsRef = query(
      this.lessonsCollection,
      or(
        where('studentId', '==', this.userStore.userData().id),
        where('instructorId', '==', this.userStore.userData().id),
      ),
    )
    return useCollection(userLessonsRef.withConverter(LessonsConverter), { ssrKey: 'user-lessons' })
  }

  getInstructorLessons() {
    const userLessonsRef = query(
      this.lessonsCollection,
      where('instructorId', '==', this.userStore.userData().id),
    )
    return useCollection(userLessonsRef.withConverter(LessonsConverter), { ssrKey: 'instructor-lessons' })
  }

  getLessonsWithStudent(studentId) {
    const userLessonsRef = query(
      this.lessonsCollection,
      where('studentId', '==', studentId),
      where('instructorId', '==', this.userStore.userData().id),
    )
    return useCollection(userLessonsRef.withConverter(LessonsConverter), { ssrKey: 'lessons-with-student' })
  }

  async addEvent(lesson, event) {
    event.createdAt = serverTimestamp()
    const eventsCollectionRef = collection(this.getRef(lesson.id), 'events')
    return addDoc(eventsCollectionRef, event)
  }

  getEventsRef(lessonId) {
    const eventsCollectionRef = query(
      collection(this.getRef(lessonId), 'events'),
    )
    return eventsCollectionRef
  }

  listAvailableLessonTypes() {
    return doc(db, 'stats', 'lessonTypes')
  }

  listLessonTypes() {
    return query(
      collection(db, 'users'),
      where('type', '==', 'instructor'),
      limit(100),
    )
  }

  async createPackage(data) {
    return createPackage(data)
  }

  getLessonPackages() {
    const packagesRef = query(
      collection(db, 'packages'),
      or(
        where('studentId', '==', this.userStore.userData().id),
        where('instructorId', '==', this.userStore.userData().id),
      ),
    )
    return useCollection(packagesRef, { ssrKey: 'user-packages' })
  }

  async updateLessonNotes({ lesson, instructorNotes }) {
    return await updateLessonNotes({ lesson, instructorNotes })
  }
}
