<template>
  <ion-badge
    v-if="count" id="pending-lessons-count" color="danger"
    data-testid="pending-lessons-count" class="animate__animated animate__swing animate__slow animate__infinite"
  >
    {{ count }}
  </ion-badge>
</template>

<script setup>
import { IonBadge } from '@ionic/vue'
import { computed } from 'vue'

import { LessonInvitesRepository } from '@/data/LessonInvite'
import { LessonsRepository } from '@/data/LessonsRepository'
import { groupBy } from 'lodash'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const InvitesRepo = new LessonInvitesRepository()
const invites = InvitesRepo.getInvites()

const lessonsRepo = new LessonsRepository()
const lessons = lessonsRepo.getUserLessons()
const count = computed(() => {
  let result = groupBy(lessons.value, lesson => lesson.stateGroup())['pending'] || []
  result = result.filter(lesson => lesson.isAwaitingActionBy(userStore.is.type))
  if (userStore.is.student) {
    const pendingInvites = invites.value.filter(invite => invite.inviteState === 'pending')
    result.push(...pendingInvites)
  }
  return result.length > 0 ? result.length : ''
})
</script>

<style>
#pending-lessons-count {
  position: absolute;
  top: 3px;
  right: 0px;
  min-width: 18px;
  height: 18px;
  border-radius: 9px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
}
</style>
