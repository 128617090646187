<template>
  <ion-page>
    <ion-content class="ion-padding-vertical" color="light">
      <template v-if="!userChats.length">
        <ion-card style="text-align:center">
          <ion-card-header>
            <ion-card-title>No Conversations Yet</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p>Discover new connections and get expert advice by starting a chat from an instructor's profile or a booked lesson page.</p>
          </ion-card-content>
        </ion-card>
      </template>
      <ion-list v-if="userChats.length">
        <template v-for="chat in userChats" :key="chat.id">
          <ion-item
            data-testid="chat-card" detail="true" lines="full"
            :router-link="{name: 'chat', params: {chatId: chat.id}}"
          >
            <user-avatar slot="start" :url="chatInfo(chat).otherUser.photoURL" :name="chatInfo(chat).otherUser.name" />
            <ion-label>
              <span v-if="chatInfo(chat).isRead">{{ chatInfo(chat).otherUser.name }}</span>
              <strong v-if="!chatInfo(chat).isRead">{{ chatInfo(chat).otherUser.name }}</strong>
              <template v-if="chat.lastMessage">
                <br>
                <ion-note>{{ chatInfo(chat).lastMessagePreview }}</ion-note>
                <p>{{ chatInfo(chat).lastActivity }}</p>
              </template>
            </ion-label>
            <ion-icon
              v-if="!chatInfo(chat).isRead" slot="end" :icon="ellipse"
              data-testid="chat-unread-icon" style="color: #1982FC; fill: #1982FC" size="small"
            />
          </ion-item>
        </template>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent,
  IonIcon, IonItem, IonLabel, IonList, IonNote, IonPage,
} from '@ionic/vue'

import { ellipse } from 'ionicons/icons'
import { useCollection } from 'vuefire'

import UserAvatar from '@/3_widgets/UserAvatar.vue'
import { useChatsStore } from '@/5_entities/chats/store'

const chatsStore = useChatsStore()

const userChats = useCollection(chatsStore.userChatsRef(), { ssrKey: 'user-chats' })

function chatInfo(chat) {
  return chatsStore.getChatInfo(chat)
}
</script>
