<template>
  <ion-spinner v-if="isLoading" />
  <ion-card v-if="currentCalendarUrl && !isLoading" style="margin-top:3em">
    <ion-card-header>
      <ion-card-title>
        <ion-icon :icon="calendarOutline" />
        Subscribe to Lessons Calendar
      </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <p class="ion-padding-bottom" style="padding-top: 0">Stay up-to-date with your lesson schedule by subscribing to your lessons calendar feed. Choose your preferred calendar application:</p>
      <ion-segment v-model="selectedCalendarApp" scrollable="true">
        <ion-segment-button value="webcal">
          <ion-label>Apple</ion-label>
          <ion-icon :icon="logoApple" />
        </ion-segment-button>
        <ion-segment-button value="google">
          <ion-label>Google</ion-label>
          <ion-icon :icon="logoGoogle" />
        </ion-segment-button>
        <ion-segment-button value="outlook">
          <ion-label>Outlook</ion-label>
          <ion-icon :icon="logoMicrosoft" />
        </ion-segment-button>
        <ion-segment-button value="other">
          <ion-label>Other</ion-label>
          <ion-icon :icon="calendarOutline" />
        </ion-segment-button>
      </ion-segment>

      <div class="ion-margin-top">
        <template v-if="selectedCalendarApp == 'webcal'">
          <ion-button
            size="small" fill="outline" color="success"
            expand="block" @click="subscribeToCalendar('webcal')"
          >
            <ion-icon :icon="logoApple" />
            &nbsp;Subscribe with Apple Calendar
          </ion-button>
          <p>Updates every 5 minutes (configurable).</p>
        </template>

        <template v-if="selectedCalendarApp == 'google'">
          <ion-button
            size="small" fill="outline" color="success"
            expand="block" class="ion-margin-top" @click="subscribeToCalendar('google')"
          >
            <ion-icon :icon="logoGoogle" />
            &nbsp;Google Calendar
          </ion-button>
          <p>Updates every 12–24h.<br>Google controls update timing.</p>
          <p v-if="isPlatform('android')">
            <strong style="color: var(--ion-color-danger)">Android Users:</strong> The Google Calendar mobile app doesn't support adding new calendar URL subscriptions. Add this calendar to Google Calendar from a <strong>desktop browser</strong>.
          </p>
        </template>
        <template v-if="selectedCalendarApp == 'outlook'">
          <ion-button
            size="small" fill="outline" color="success"
            expand="block" class="ion-margin-top" @click="subscribeToCalendar('outlook')"
          >
            <ion-icon :icon="logoMicrosoft" />
            &nbsp;Microsoft Outlook
          </ion-button>
          <p>Updates every 12–24h.<br>Outlook controls update timing.</p>
        </template>

        <template v-if="selectedCalendarApp == 'other'">
          <ion-textarea
            readonly="true" :value="calendarUrls.webcal" label="iCal URL (for other calendar apps):"
            label-placement="stacked" rows="2" mode="md"
            auto-grow="true"
            fill="outline" class="ion-margin-top"
          />
          <p>
            <strong style="color: var(--ion-color-danger)">Important:</strong> This URL is unique and grants access to your personal schedule. Protect it to maintain privacy.
            <ul>
              <li>Do not share it with anyone!</li>
              <li>Contact support if you suspect it's been compromised.</li>
            </ul>
          </p>
        </template>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
  IonIcon, IonLabel, IonSegment, IonSegmentButton,
  IonSpinner, IonTextarea, isPlatform,
} from '@ionic/vue'
import { computed, onMounted, ref } from 'vue'

import { calendarOutline, logoApple, logoGoogle, logoMicrosoft } from 'ionicons/icons'
import { SM } from '@/globals'
import { createCalendarUrl } from '@/firebase'
import { useUserStore } from '@/stores/user'

const selectedCalendarApp = ref('webcal')

const FUNCTION_URL = SM.env === 'development'
  ? 'http://127.0.0.1:5001/swingmatch-8a936/us-west1/generateCalendarFeed'
  : 'https://generateCalendarFeed-aque4yeqwa-uw.a.run.app/generateCalendarFeed'

const userStore = useUserStore()

const currentCalendarUrl = computed(() => {
  if (userStore.userData().calendarFeedUuid) {
    return `${FUNCTION_URL}?id=${userStore.userData().calendarFeedUuid}`
  } else {
    return ''
  }
})

const isLoading = ref(false)
onMounted(async () => {
  if (!userStore.userData().calendarFeedUuid) createUrl()
})

async function createUrl() {
  isLoading.value = true
  const response = await createCalendarUrl()
  isLoading.value = false
  console.log(response)
}

const calendarUrls = computed(() => {
  const encodedUrl = encodeURIComponent(currentCalendarUrl.value)
  return {
    google: `https://calendar.google.com/calendar/r?cid=${encodedUrl.replace('https', 'http')}`,
    webcal: `webcal://${currentCalendarUrl.value.replace('https://', '').replace('http://', '')}`,
    outlook: `https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addsubscription&url=${encodedUrl}&name=SwingMatch%20Calendar`,
  }
})

function subscribeToCalendar(type) {
  if (type in calendarUrls.value) {
    window.open(calendarUrls.value[type], '_blank')
  } else {
    console.error(`Unsupported calendar type: ${type}`)
  }
}
</script>

<style scoped>
p {
  padding-top: 8px;
}
ul {
  margin-top: 8px;
  margin-bottom: 0;
}
</style>
