import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { initializeApp } from 'firebase/app'

import { SM } from './globals'

const firebaseConfig = { apiKey: 'AIzaSyDEayJ_rCSUsei8o84GhsTQ67htVh_TwgE',
  authDomain: 'app.swingmatch.ca',
  projectId: 'swingmatch-8a936',
  appId: '1:288381231224:web:56121020d24168966fcd8d',
  storageBucket: 'gs://swingmatch-8a936.appspot.com/',
}
export const firebaseApp = initializeApp(firebaseConfig)
export const auth = getAuth()
export const db = getFirestore(firebaseApp)
const functions = getFunctions(firebaseApp, 'us-west1')
const storage = getStorage(firebaseApp)

if (SM.env === 'development') {
  connectFirestoreEmulator(db, '127.0.0.1', 8080)
  connectAuthEmulator(auth, 'http://127.0.0.1:9099')
  connectFunctionsEmulator(functions, '127.0.0.1', 5001)
  connectStorageEmulator(storage, '127.0.0.1', 9199)
}

import { firestoreDefaultConverter, globalFirestoreOptions } from 'vuefire'

globalFirestoreOptions.converter = {
  // the default converter just returns the data: (data) => data
  toFirestore: firestoreDefaultConverter.toFirestore,
  fromFirestore: (snapshot, options) => {
    const data = firestoreDefaultConverter.fromFirestore(snapshot, options)
    // if the document doesn't exist, return null
    if (!data) return null
    // add anything custom to the returned object
    // data.metadata = snapshot.metadata
    // data.parent = snapshot.ref.path
    return data
  },
}

export const listTimeslots = httpsCallable(functions, 'listTimeslots')
export const createStripeSession = httpsCallable(functions, 'createStripeSession')
export const createLessonAndAuthorizePayment = httpsCallable(functions, 'createLessonAndAuthorizePayment')

export const createStripeSessionForPackage = httpsCallable(functions, 'createStripeSessionForPackage')
export const createPackage = httpsCallable(functions, 'createPackage')

export const createInvite = httpsCallable(functions, 'createInvite')
export const declineInvite = httpsCallable(functions, 'declineInvite')

export const declineLessonByInstructor = httpsCallable(functions, 'declineLessonByInstructor')
export const confirmLessonByInstructor = httpsCallable(functions, 'confirmLessonByInstructor')
export const requestReschedule = httpsCallable(functions, 'requestReschedule')
export const answerRescheduleRequest = httpsCallable(functions, 'answerRescheduleRequest')
export const rescheduleOfflineLesson = httpsCallable(functions, 'rescheduleOfflineLesson')
export const cancelLesson = httpsCallable(functions, 'cancelLesson')
export const updateOfflinePaymentStatus = httpsCallable(functions, 'updateOfflinePaymentStatus')
export const sendPaymentReminder = httpsCallable(functions, 'sendPaymentReminder')
export const updateLessonNotes = httpsCallable(functions, 'updateLessonNotes')

export const chatsInit = httpsCallable(functions, 'chatsInit')
export const verifyPhoneInit = httpsCallable(functions, 'verifyPhoneInit')
export const verifyPhoneCheck = httpsCallable(functions, 'verifyPhoneCheck')
export const reportError = httpsCallable(functions, 'reportError')

export const createCalendarUrl = httpsCallable(functions, 'createCalendarUrl')

export const testLoginAs = httpsCallable(functions, 'testLoginAs')
