<template>
  <ion-row v-if="hasPackages">
    <ion-col>
      <ion-button
        fill="outline" expand="block" color="medium"
        class="ion-no-margin" @click="showPackagesActionSheet"
      >
        Buy Lesson Package
      </ion-button>
      <ion-modal ref="packagePurchaseModalRef">
        <package-purchase-modal
          :pckg="selectedPackage" :instructor="instructor" @cancel="packagePurchaseModalRef.$el.dismiss()"
          @package:purchase="createPackage"
        />
      </ion-modal>
    </ion-col>
  </ion-row>
</template>

<script setup>
import { IonButton, IonCol, IonModal, IonRow, actionSheetController } from '@ionic/vue'
import { computed, ref } from 'vue'
import { useCurrentUser } from 'vuefire'
import { useRouter } from 'vue-router'

import { Instructor } from '@/5_entities/instructors/Instructor'
import { LessonsRepository } from '@/data/LessonsRepository'
import PackagePurchaseModal from '@/3_widgets/packages/PackagePurchaseModal.vue'
import { sendRequest } from '@/6_shared/utils/sendRequest'
import { trackEvent } from '@/globals'

const props = defineProps({
  instructor: { required: true, type: Object },
})
const currentUser = useCurrentUser()
const router = useRouter()

const packages = computed(() => {
  const instructor = new Instructor(props.instructor)
  return instructor.packages()
})

const hasPackages = computed(() => packages.value.length > 0)

const packagePurchaseModalRef = ref()
const selectedPackage = ref()

async function showPackagesActionSheet() {
  const actionSheet = await actionSheetController.create({
    buttons: [
      ...packages.value.map(pkg => ({
        text: `${pkg.lessons} lessons (${pkg.name}) - $${pkg.price}, ${pkg.location.name}`,
        handler: () => {
          if (currentUser.value) {
            selectedPackage.value = pkg
            packagePurchaseModalRef.value.$el.present()
          } else {
            router.push({ query: { show: 'login' }})
          }
        },
      })),
      { text: 'Cancel', role: 'cancel' },
    ],
    cssClass: 'time-picker-action-sheet',
    htmlAttributes: {
      ['data-testid']: 'time-picker-action-sheet',
    },
  })
  await actionSheet.present()
}

async function createPackage(paymentInfo) {
  const LessonsRepo = new LessonsRepository()
  await sendRequest('Purchasing Package...', async () => {
    selectedPackage.value.paymentInfo = paymentInfo
    await LessonsRepo.createPackage({ package: selectedPackage.value })
    packagePurchaseModalRef.value.$el.dismiss()
    trackEvent('package_purchased', { lessons: selectedPackage.value.lessons, price: selectedPackage.value.price, name: selectedPackage.value.name })
    alert(`Successfully purchased package. You can now book lessons with ${props.instructor.name}.`)
    location.reload()
  })
}

</script>
