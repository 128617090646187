<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title v-if="instructor">
          Book {{ instructor.firstName }}
        </ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{name: 'lessons'}" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content
      v-if="instructor" color="light" class="ion-padding"
      data-testid="book-lesson-page"
    >
      <template
        v-if="!filteredPricingTypes.length"
      >
        No timeslots available
      </template>
      <template
        v-if="filteredPricingTypes.length"
      >
        <ion-item v-if="filteredPricingTypes.length > 1" lines="none">
          <ion-select v-model="selectedPricingType" interface="action-sheet" justify="end">
            <ion-select-option v-for="pricingType in filteredPricingTypes" :key="pricingType" :value="pricingType">
              {{ ALL_PRICING_TYPES[pricingType].name.includes('Lesson') ? ALL_PRICING_TYPES[pricingType].name : `${ALL_PRICING_TYPES[pricingType].name} Lesson` }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item v-if="timeslotsLocations.length" lines="none">
          <!-- <ion-button
            id="show-location" slot="end" fill="clear"
            size="default"
          >
            <ion-icon :icon="locationOutline" />
          </ion-button> -->
          <ion-select v-model="selectedLocation" interface="action-sheet" justify="end">
            <ion-select-option v-if="timeslotsLocations.length > 1" value="all">All locations</ion-select-option>
            <ion-select-option v-for="location in timeslotsLocations" :key="location" :value="location">
              {{ location.name }}
            </ion-select-option>
          </ion-select>
          <!-- <ion-modal id="info-modal" ref="infoModal" trigger="show-location">
            <div class="ion-padding">
              <pre v-if="selectedLocation != 'all'">{{ selectedLocation }}</pre>
              <pre v-else>{{ timeslotsLocations }}</pre>
            </div>
          </ion-modal> -->
        </ion-item>
        <timeslots-calendar
          :key="rerenderKey" v-model:locations="timeslotsLocations"
          :instructor="instructor" :selected-location="selectedLocation"
          :selected-pricing-type="selectedPricingType"
          :current-user="userStore.is.type"
          @timeslot:selected="selectTimeslot"
        />
        <ion-item lines="none" color="light">
          <ion-label>Don't see a time that works for you?</ion-label>
        </ion-item>
        <ion-button
          color="success" fill="outline" expand="block"
          size="small"
          :router-link="
            currentUser ? {name: 'chat', params: {
              chatId: [currentUser.uid, instructor?.id].sort().join('---')
            }} : {query: {show: 'login'}}"
        >
          <ion-icon slot="start" :icon="chatbubblesOutline" />
          Chat
        </ion-button>
      </template>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem,
  IonLabel, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar, modalController,
  onIonViewWillEnter,
  onIonViewWillLeave,
} from '@ionic/vue'
import { computed, onMounted, ref, watch } from 'vue'
import { useCurrentUser, useDocument } from 'vuefire'
import { useRoute, useRouter } from 'vue-router'
import { chatbubblesOutline } from 'ionicons/icons'
import { watchOnce } from '@vueuse/core'

import { ALL_PRICING_TYPES } from '@/data/Instructor.schema'
import { Instructor } from '@/5_entities/instructors/Instructor'
import { LessonsRepository } from '@/data/LessonsRepository'
import TimeslotModal from '@/views/golfers/lessons/TimeslotModal.vue'
import TimeslotsCalendar from '@/views/golfers/lessons/TimeslotsCalendar.vue'
import { sendRequest } from '@/6_shared/utils/sendRequest'
import { trackEvent } from '@/globals'
import { useUserStore } from '@/stores/user'

const currentUser = useCurrentUser()
const userStore = useUserStore()
const LessonsRepo = new LessonsRepository()
const router = useRouter()
const route = useRoute()

const props = defineProps({
  instructorId: { type: String, required: true },
})

function preselectLessonType() {
  if (route.query.lessonType) {
    selectedPricingType.value = Object.values(ALL_PRICING_TYPES).find(pricingType => pricingType.name === route.query.lessonType)?.key
  }
  selectedPricingType.value ||= filteredPricingTypes.value[0] || 'adult'
}
const instructorDoc = userStore.student.fn.getInstructor(props.instructorId)
const instructor = computed(() => instructorDoc.value && new Instructor(instructorDoc.value))

watchOnce(instructor, () => {
  setTimeout(preselectLessonType, 100)
})

const selectedLocation = ref('all')
const selectedPricingType = ref('adult')
const timeslotsLocations = ref([])
const selectedTimeslot = ref()

const filteredPricingTypes = computed(() => {
  if (!instructor.value) return []
  if (!instructor.value.allPricingTypes) return []
  return instructor.value.allPricingTypes()
})

watch(timeslotsLocations, (newVal) => {
  if (newVal.length > 1 || newVal.length === 0) {
    selectedLocation.value = 'all'
  } else {
    selectedLocation.value = newVal[0]
  }
})

async function selectTimeslot(timeslot) {
  if (currentUser.value) {
    selectedTimeslot.value = timeslot
    const modal = await modalController.create({
      component: TimeslotModal,
      componentProps: {
        timeslot,
        instructor: instructor.value,
      },
      backdropDismiss: false,
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()

    if (data?.action === 'create') {
      await createLesson(data.lesson)
    } else if (data?.action === 'cancelInvite') {
      cancelInvite()
    }
  } else {
    router.push({ query: { show: 'login' }})
  }
}

async function createLesson(lesson) {
  await sendRequest('Creating Lesson...', async () => {
    const result = (await LessonsRepo.createLessonAndAuthorizePayment(lesson)).data
    router.replace({ name: 'lessonPage', params: { lessonId: result.lesson.id }})
    modalController.dismiss()
    trackEvent('lesson_booked')
  }, lesson)
}

onIonViewWillLeave(() => {
  modalController.dismiss()
})

import { LessonInvitesRepository } from '@/data/LessonInvite'
const invite = ref(null)
const InvitesRepo = new LessonInvitesRepository()
onMounted(async () => {
  const inviteId = route.query.inviteId
  if (inviteId) {
    useDocument(InvitesRepo.getRef(inviteId), { target: invite }).promise.value.then(() => {
      setTimeout(() => selectTimeslot(invite.value), 500)
    })
  }
})

const rerenderKey = ref(0)
onIonViewWillEnter(async () => {
  rerenderKey.value += 1
})

function cancelInvite() {
  router.replace({ name: 'invite', params: { inviteId: selectedTimeslot.value.inviteId }})
}
</script>
