import { actionSheetController } from '@ionic/vue'
import { ref } from 'vue'

import { formatTime } from '@/6_shared/utils/formattingLessons'

const CSS_CLASS = 'time-picker-action-sheet'
const START_HOUR = 7
const END_HOUR = 22 // Assuming the last time is 9:45 PM
const MINUTE_INTERVAL = 15
const lastScrollPosition = ref(null)

function injectTimePickerStyles() {
  if (!document.getElementById('time-picker-styles')) {
    const styleElement = document.createElement('style')
    styleElement.id = 'time-picker-styles'
    styleElement.textContent = `
      .time-picker-action-sheet button.time-bold {
        font-weight: bold;
        color: var(--ion-color-medium) !important;
        border-top: 1px solid var(--ion-color-medium) !important;
      }`
    document.head.appendChild(styleElement)
  }
}

export function generateTimes() {
  const times = []
  for (let hour = START_HOUR; hour <= END_HOUR; hour++) {
    for (let minute = 0; minute < 60; minute += MINUTE_INTERVAL) {
      const timeData = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
      const timeString = formatTime(timeData, { includeZeroes: true, lowercase: true })
      times.push({
        text: timeString,
        data: timeData,
        role: 'time',
        cssClass: minute === 0 ? 'time-bold' : '',
      })
    }
  }
  return times
}

export async function showTimePickerActionSheet(callback) {
  injectTimePickerStyles()
  const actionSheet = await actionSheetController.create({
    buttons: [
      ...generateTimes(),
      { text: 'Cancel', role: 'cancel' },
    ],
    cssClass: CSS_CLASS,
    htmlAttributes: {
      ['data-testid']: CSS_CLASS,
    },
  })

  await actionSheet.present()

  // Restore scroll position after sheet is presented
  requestAnimationFrame(() => {
    const element = document.querySelector(`.${CSS_CLASS} .action-sheet-group`)
    if (element && lastScrollPosition.value !== null) {
      element.scrollTo({
        top: lastScrollPosition.value,
        behavior: 'instant',
      })
    }
  })

  const { data, role } = await actionSheet.onWillDismiss()

  // Store scroll position before dismissal
  const element = document.querySelector(`.${CSS_CLASS} .action-sheet-group`)
  if (element) {
    lastScrollPosition.value = element.scrollTop
  }

  if (role === 'time') callback(data)
}
