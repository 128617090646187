<template>
  <ion-page data-testid="student-page">
    <ion-header>
      <ion-toolbar>
        <ion-title>
          {{ student?.name }}

          <span v-if="student && student.id.startsWith('offline_')">
            <ion-icon :icon="cloudOfflineOutline" />
          </span>
        </ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{name: 'students'}" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" class="ion-padding-top">
      <ion-spinner v-if="studentPending" />
      <error-message
        v-if="!student && !studentPending"
        title="No Student Found"
        :content="studentId.startsWith('offline_') ? 'Offline student was deleted' : 'Please check the student ID and try again'"
      />
      <template v-if="student">
        <ion-grid>
          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col class="ion-align-items-center" size="auto">
              <user-avatar :url="student.photoURL" :size="100" :name="student.name" />
            </ion-col>
          </ion-row>
          <ion-row v-if="isOfflineStudent" class="ion-text-center">
            <ion-col>
              <p>
                <strong>Email</strong>:
                <a v-if="student.email" :href="`mailto:${student.email}`">{{ student.email }}</a>
                <ion-note v-else>unknown</ion-note>
              </p>
              <p>
                <strong>Phone</strong>:
                <span v-if="student.phone">{{ student.phone }}</span>
                <ion-note v-else>unknown</ion-note>
              </p>
              <p v-if="student.notes">
                <strong>Notes:</strong> "{{ student.notes }}"
              </p>
            </ion-col>
          </ion-row>
        </ion-grid>
        <purchased-packages-block :display-book-button="false" :filter-by-user="student" />
        <lessons-block :filter-by-user="student" />
      </template>
    </ion-content>
    <ion-footer>
      <ion-toolbar v-if="student">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-button
                v-if="!isOfflineStudent" expand="block" fill="outline"
                color="success" :router-link="{
                  name: 'chat', params: {
                    chatId: [currentUser.uid, student?.id].sort().join('---')
                  }
                }"
              >
                <ion-icon slot="start" :icon="chatbubblesOutline" />
                &nbsp;Chat
              </ion-button>
              <ion-button
                v-if="isOfflineStudent" expand="block" fill="outline"
                color="medium" :router-link="{
                  name: 'editOfflineStudent', params: {studentId: student?.id}
                }"
                data-testid="edit-student-button"
              >
                <ion-icon slot="start" :icon="createOutline" />
                &nbsp;Edit
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button
                expand="block" color="success"
                data-testid="book-lesson-button" :router-link="{
                  name: 'createLessonPage',
                  params: { studentId: student?.id }
                }"
              >
                <ion-icon slot="start" :icon="calendarOutline" />
                &nbsp;
                <span v-if="isOfflineStudent">Book Lesson</span>
                <span v-else>Lesson Invite</span>
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-button
                v-if="isOfflineStudent" fill="outline" expand="block"
                color="success" class="ion-no-margin" :router-link="{
                  name: 'createPackagePage',
                  params: {studentId: student?.id}
                }"
              >
                Book Lesson Package
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script setup>
import { IonBackButton, IonButton, IonButtons, IonCol, IonContent,
  IonFooter, IonGrid, IonHeader, IonIcon, IonNote, IonPage, IonRow,
  IonSpinner, IonTitle, IonToolbar,
} from '@ionic/vue'
import { calendarOutline, chatbubblesOutline, cloudOfflineOutline, createOutline } from 'ionicons/icons'
import { computed } from 'vue'
import { useCurrentUser } from 'vuefire'

import ErrorMessage from '@/3_widgets/ErrorMessage.vue'
import LessonsBlock from '@/shared/components/LessonsBlock.vue'
import PurchasedPackagesBlock from '@/3_widgets/packages/PurchasedPackagesBlock.vue'
import UserAvatar from '@/3_widgets/UserAvatar.vue'
import { useUserStore } from '@/stores/user'

const currentUser = useCurrentUser()
const userStore = useUserStore()

const props = defineProps({
  studentId: { type: String, required: true },
})

const isOfflineStudent = computed(() => props.studentId.startsWith('offline_'))

const { data: student, pending: studentPending } = userStore.instructor.fn.getStudent(props.studentId)

</script>
