<template>
  <ion-card v-if="filteredPurchasedPackages.length">
    <ion-card-header class="ion-no-padding ion-padding-horizontal ion-padding-top">
      <ion-card-title>Lesson Packages</ion-card-title>
    </ion-card-header>
    <ion-card-content class="ion-no-padding ion-padding-horizontal">
      <ion-list style="margin:0">
        <ion-item
          v-for="pckg in filteredPurchasedPackages" :key="pckg.id" lines="full"
          class="ion-no-padding"
        >
          <ion-grid class="ion-no-padding">
            <ion-row class="ion-align-items-center ion-padding-vertical">
              <ion-col>
                <strong>{{ pckg.lessonsLeft }} of {{ pckg.lessons }} {{ pckg.name }}</strong> prepaid lessons left {{ otherPartyName(pckg) }}
                <p v-if="offlinePackage(pckg) && userStore.is.instructor" size="auto" class="ion-no-padding">
                  <template v-if="needsPayment(pckg)">
                    <ion-button @click="markAsPaid(pckg)">Mark as Paid (${{ pckg.paymentInfo.metadata.totalPayment }})</ion-button>
                    <ion-button fill="outline" @click="sendPaymentReminder(pckg)">Send Payment Reminder</ion-button>
                  </template>
                  <ion-button
                    v-else size="small" fill="outline"
                    color="danger" @click="markAsUnpaid(pckg)"
                  >
                    Mark as Unpaid
                  </ion-button>
                </p>
              </ion-col>
              <ion-col v-if="displayBookButton && userStore.is.student" size="auto" class="ion-no-padding">
                <ion-button
                  fill="outline" color="success"
                  size="small" style="margin-left: 1em"
                  data-testid="package-book-button"
                  :disabled="pckg.lessonsLeft == 0"
                  @click="bookLesson(pckg)"
                >
                  Book
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonItem, IonList, IonRow, toastController } from '@ionic/vue'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import { LessonsRepository } from '@/data/LessonsRepository'
import { sendRequest } from '@/6_shared/utils/sendRequest'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

const props = defineProps({
  displayBookButton: { required: false, type: Boolean, default: true },
  filterByUser: { required: false, type: Object, default: null },
})

const router = useRouter()

const LessonsRepo = new LessonsRepository()
const purchasedPackages = LessonsRepo.getLessonPackages()

const filteredPurchasedPackages = computed(() => {
  const result = purchasedPackages.value.filter(pckg => pckg.lessonsLeft > 0 || needsPayment(pckg))
  if (!props.filterByUser) return result
  return result.filter(pckg => pckg.instructor.id === props.filterByUser.id || pckg.student.id === props.filterByUser.id)
})

function otherPartyName(pckg) {
  if (props.filterByUser) return ''
  if (userStore.is.student) return `with ${pckg.instructor.name}`
  if (userStore.is.instructor) return `with ${pckg.student.name}`
}

async function bookLesson(pckg) {
  router.push({ name: 'bookLesson', params: { instructorId: pckg.instructor.id }, query: { lessonType: pckg.name }})
}

function needsPayment(pckg) {
  return pckg.paymentInfo.status === 'authorized'
}

function offlinePackage(pckg) {
  return pckg.paymentInfo?.metadata?.paymentMethod === 'offline'
}

async function markAsPaid(pckg) {
  await sendRequest('Updating Package...', async () => {
    await LessonsRepo.updateOfflinePaymentStatus({ pckg: pckg, paymentStatus: 'paid' })
  })
}

async function markAsUnpaid(pckg) {
  await sendRequest('Updating Package...', async () => {
    await LessonsRepo.updateOfflinePaymentStatus({ pckg: pckg, paymentStatus: 'authorized' })
  })
}

async function sendPaymentReminder(pckg) {
  await sendRequest('Sending Payment Reminder...', async () => {
    await LessonsRepo.sendPaymentReminder({ pckg: pckg })
  })
  const toast = await toastController.create({
    message: 'Payment Reminder Sent',
    duration: 1000,
    color: 'success',
  })
  await toast.present()
}
</script>
