<template>
  <ion-item
    lines="full" detail="true" data-testid="selected-package"
    @click="openModal"
  >
    <ion-label v-if="selectedPackage">
      <strong>{{ selectedPackage.location.name }}</strong>
      <br>
      <ion-note>{{ formatPackageDetails(pckg) }}</ion-note>
    </ion-label>
    <ion-label v-else>
      <ion-note>Select lesson type</ion-note>
    </ion-label>

    <ion-modal id="choose-package-modal" ref="modalRef" data-testid="choose-package-modal">
      <ion-list>
        <template v-for="(types, locationName) in groupedOptions" :key="locationName">
          <ion-item>
            <ion-label><strong>{{ locationName }}</strong></ion-label>
          </ion-item>
          <ion-item
            v-for="option in types" :key="`${option.lessons}-${option.name}-${option.price}`"
            lines="none" :detail="true"
            @click="selectAndClose(option)"
          >
            <ion-label>
              {{ formatPackageDetails(option) }}
            </ion-label>
          </ion-item>
        </template>
      </ion-list>
    </ion-modal>
  </ion-item>
</template>

<script setup>
import { IonItem, IonLabel, IonList, IonModal, IonNote } from '@ionic/vue'
import { computed, ref } from 'vue'

const props = defineProps({
  pckg: { type: Object, required: true },
  packages: { type: Array, required: true, default: () => [] },
})

const emit = defineEmits(['selectPackage'])
const modalRef = ref()

const selectedPackage = computed(() => props.pckg.packageType)

const availableOptions = computed(() => {
  return props.packages
})

const groupedOptions = computed(() => {
  return availableOptions.value.reduce((grouped, option) => {
    const locationName = option.location.name
    grouped[locationName] ??= []
    grouped[locationName].push(option)
    return grouped
  }, {})
})

function formatPackageDetails(pckg) {
  return `${pckg.lessons} ${pckg.name} Lessons - $${pckg.price}`
}

function openModal() {
  modalRef.value?.$el?.present()
}

async function selectAndClose(option) {
  emit('selectPackage', option)
  modalRef.value?.$el?.dismiss()
}

// Auto-select first option if none selected
if (!props.pckg.packageType && availableOptions.value.length) {
  selectAndClose(availableOptions.value[0])
}
</script>

<style>
/* CAN NOT BE SCOPED */
ion-modal#choose-package-modal {
  --width: 90%;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 14px;
}
</style>
