<template>
  <ion-page data-testid="chat-show-page">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{name: 'chats'}" data-testid="chat-back-button" />
        </ion-buttons>
        <ion-title data-testid="chat-title">
          {{ chatInfo?.otherUser?.name }}
        </ion-title>
        <ion-buttons v-if="linkToUserProfile" slot="end">
          <ion-button :router-link="linkToUserProfile" data-testid="chat-user-profile-button">
            <ion-icon :icon="personOutline" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content ref="contentRef" class="ion-padding-vertical" color="light">
      <ion-button
        v-if="chatObj && chatMessages?.length < chatObj.msgCount" expand="block" color="dark"
        fill="outline" size="small" @click="loadMore"
      >
        Load more
      </ion-button>
      <error-message v-if="errorMsg" :title="errorMsg" />
      <ion-item v-if="!chatObj" class="ion-padding" lines="none">
        <ion-spinner />
        <ion-label>&nbsp;Loading chat...</ion-label>
      </ion-item>
      <div v-if="chatObj" class="ion-padding">
        <chat-rules v-if="chatMessages.length === 0" />
        <message-bubble
          v-for="message in reversedMessages" :key="message.id" :message="message"
          :is-last-read="chatInfo?.lastMsgReadByOtherUser?.id == message.id"
          :is-mine="message.user === userStore.userData().id"
          :avatar-url="chatInfo.otherUser.photoURL"
        />
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar v-if="chatObj">
        <ion-item lines="none">
          <ion-textarea
            v-model="newMessage" placeholder="Write a message..."
            rows="1" :auto-grow="newMessage.split('\n').length <= 10"
            data-testid="chat-send-message"
          />
          <ion-button
            fill="clear" size="default" data-testid="chat-send-button"
            :disabled="!newMessage.trim().length" @click="sendMesssage"
          >
            <ion-icon :icon="send" />
          </ion-button>
        </ion-item>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script setup>
import { IonBackButton, IonButton, IonButtons, IonContent, IonFooter,
  IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonSpinner, IonTextarea, IonTitle, IonToolbar, onIonViewDidLeave,
} from '@ionic/vue'
import { computed, getCurrentInstance, onUnmounted, ref, watch } from 'vue'
import { personOutline, send } from 'ionicons/icons'
import { useCollection, useDocument } from 'vuefire'

import ChatRules from '@/3_widgets/chat/ChatRules.vue'
import ErrorMessage from '@/3_widgets/ErrorMessage.vue'
import MessageBubble from '@/3_widgets/chat/MessageBubble.vue'
import { trackEvent } from '@/globals'
import { useChatsStore } from '@/5_entities/chats/store'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const chatStore = useChatsStore()

const props = defineProps({
  chatId: { type: String, required: true },
})

const contentRef = ref()
const chatObj = ref(null)
const chatMessagesRef = computed(() => chatStore.getMessagesRef(props.chatId, limit.value))
const chatMessages = ref([])
const errorMsg = ref('')
const limit = ref(50)
const shouldScrollToBottom = ref(true)

trackEvent('opened_chat')

chatStore.init(props.chatId).then(() => {
  useDocument(chatStore.getChatRef(props.chatId), { target: chatObj })
  useCollection(chatMessagesRef, {
    target: chatMessages,
    once: false,
    ssrKey: 'chats-show-messages',
  })
  // eslint-disable-next-line vue/no-ref-as-operand
  watch(() => chatObj.error?.value?.code, () => {
    // eslint-disable-next-line vue/no-ref-as-operand
    if (chatObj.error?.value?.code === 'permission-denied') {
      errorMsg.value = 'You do not have permission to access this chat'
    } else {
      errorMsg.value = ''
    }
  })
}).catch((_error) => {
  errorMsg.value = 'Error loading chat'
})

const reversedMessages = computed(() => chatMessages.value.slice().reverse())

function handleScroll() {
  if (shouldScrollToBottom.value) {
    contentRef.value.$el.scrollToBottom(500)
  } else {
    shouldScrollToBottom.value = true
  }
  chatStore.markChatAsRead(chatObj.value)
}
const unHandleScroll = watch(reversedMessages, handleScroll)
onUnmounted(unHandleScroll)

const chatInfo = computed(() => chatStore.getChatInfo(chatObj.value))

const newMessage = ref('')
async function sendMesssage() {
  trackEvent('sent_message')
  await chatStore.addMessage(props.chatId, newMessage.value)
  newMessage.value = ''
  await chatStore.markChatAsRead(chatObj.value)
}

// Use to display dates headers
// const messagesByDay = computed(() => {
//   let lastTimestamp = null
//   return reversedMessages.value.filter((message) => {
//     const messageTimestamp = new Date(message.createdAt.seconds * 1000)
//     const fiveMinutes = 24 * 60 * 60 * 1000 // 5 minutes in milliseconds, TODO: change to 5 minutes
//     const newFiveMinutes = !lastTimestamp || messageTimestamp.getTime() - lastTimestamp.getTime() >= fiveMinutes
//     if (newFiveMinutes) {
//       lastTimestamp = messageTimestamp
//     }
//     return newFiveMinutes
//   }).map(message => message.id)
// })

const loadMore = () => {
  limit.value += 50
  shouldScrollToBottom.value = false
}

const linkToUserProfile = computed(() => {
  if (!chatInfo.value) return null
  const otherUserId = chatInfo.value.otherUser.id
  if (userStore.is.instructor) {
    return { name: 'student', params: { studentId: otherUserId }}
  } else {
    return { name: 'instructor', params: { instructorId: otherUserId }}
  }
})

onIonViewDidLeave(() => {
  unHandleScroll()
  const instance = getCurrentInstance()
  if (instance) instance.proxy.$destroy()
})
</script>
