<template>
  <ion-button fill="outline" color="medium" @click="handleDownload">
    <ion-icon slot="start" :icon="calendarOutline" />
    Add to Calendar
  </ion-button>
</template>
<script setup>
import { IonButton, IonIcon } from '@ionic/vue'
import { calendarOutline } from 'ionicons/icons'
import { createEvent } from 'ics'

const props = defineProps({
  lesson: { type: Object, required: true },
  currentUser: { type: String, required: true },
})

async function handleDownload() {
  const filename = 'GolfLesson.ics'
  const file = await new Promise((resolve, reject) => {
    createEvent(props.lesson.toIcs(props.currentUser), (error, value) => {
      if (error) reject(error)
      resolve(new File([value], filename, { type: 'text/calendar' }))
    })
  })
  const url = URL.createObjectURL(file)

  // trying to assign the file URL to a window could cause cross-site
  // issues so this is a workaround using HTML5
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = filename

  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)

  URL.revokeObjectURL(url)
}

</script>
