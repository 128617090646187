<template>
  <ion-button
    :router-link="{name: 'chats'}" router-direction="none" class="chat-button"
    data-testid="chat-button"
  >
    <ion-icon slot="icon-only" :icon="chatbubblesOutline" />
    <ion-badge
      v-if="unreadChatsCount"
      color="danger"
      data-testid="unread-chats-count"
      class="animate__animated animate__swing chat-badge animate__slow animate__infinite"
    >
      {{ unreadChatsCount }}
    </ion-badge>
  </ion-button>
</template>

<script setup>
import { IonBadge, IonButton, IonIcon } from '@ionic/vue'
import { chatbubblesOutline } from 'ionicons/icons'
import { computed } from 'vue'
import { useChatsStore } from '@/5_entities/chats/store'
import { useCollection } from 'vuefire'

const chatsStore = useChatsStore()
const userChats = useCollection(chatsStore.userChatsRef(), { ssrKey: 'user-chats' })

const unreadChatsCount = computed(() => {
  const unreadChats = userChats.value.filter(chat => !chatsStore.getChatInfo(chat).isRead)
  return unreadChats.length > 0 ? unreadChats.length : ''
})
</script>

<style scoped>
.chat-button {
  position: relative;
}

.chat-badge {
  position: absolute;
  top: -2px;
  right: -4px;
  min-width: 18px;
  height: 18px;
  border-radius: 9px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
}
</style>
