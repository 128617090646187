import { ALL_PRICING_TYPES } from '@/data/Instructor.schema'
import { unref } from 'vue'

/**
 * Formats the given time to a specific format.
 *
 * @param {string|Date} time - The time to be formatted. It can be either a string in the format 'HH:mm' or a Date object.
 * @returns {string} The formatted time in the format 'h:mm AM/PM'.
 */
export function formatTime(time, options = { includeZeroes: false, lowercase: true, includeSpace: false }) {
  if (typeof time === 'string') {
    time = new Date(`2023-11-02T${time}:00`)
  }
  // drop leading zero, convert to 12-hour format, and replace AM/PM with am/pm, drop space before AM/PM

  let formattedTime = time.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })

  if (!options.includeZeroes) {
    formattedTime = formattedTime.replace(/^0|:00 /g, '')
  }

  if (options.lowercase) {
    formattedTime = formattedTime.toLowerCase()
  }

  if (!options.includeSpace) {
    formattedTime = formattedTime.replace(/ /g, '')
  }

  return formattedTime
}

/**
 * Display the full length of the lesson based on the start time and length.
*
* @param {string} startTime - The start time of the lesson in the format "HH:MM".
* @param {number} lessonLength - The length of the lesson in minutes.
* @returns {string} - The formatted string representing the full length of the lesson.
*/
export function displayFullLength(startTime, lessonLength) {
  startTime = unref(startTime)
  startTime = new Date(`2023-11-02T${startTime}:00`)
  let endTime = new Date(startTime.getTime() + lessonLength * 60 * 1000)

  if (lessonLength) {
    endTime = `–${formatTime(endTime)}`
    startTime = formatTime(startTime).replace(/(am|pm)/, '')
  } else {
    endTime = ''
    startTime = formatTime(startTime)
  }

  return `${startTime}${endTime}`
}

/**
 * Formats the pricing information and sorts.
 * @param {Array} pricing - The pricing information to be formatted.
 * @returns {Array} - The formatted pricing information.
 */
export function formatPricing(pricing) {
  return Object.keys(ALL_PRICING_TYPES).map((key) => {
    const { enabled, price } = pricing[key] || {}
    if (enabled && price) {
      return [ALL_PRICING_TYPES[key].name, price]
    }
    return null
  }).filter(Boolean)
}

/**
 * Formats the timetable schedule.
 * @param {Object} schedule - The schedule object.
 * @returns {Array} - The formatted timetable schedule.
 */
export function formatTimetable(schedule) {
  return Object.entries(schedule)
    .filter(([_day, times]) => times.length)
    .map(([day, times]) => [day, times.map(time => formatTime(time)).join(', ')])
}

/**
 * Finds overlapping times in an array of days.
 * @param {Array} days - The array of days.
 * @param {number} lessonLength - The length of the lesson in minutes.
 * @returns {Array} - The array of overlapping times.
 */
export function findOverlaps(lessonType) {
  const overlaps = []
  const days = [...lessonType.schedule.weekly.days, ...lessonType.schedule.dates.days]
  days.forEach((day) => {
    const times = day.times
    for (let i = 0; i < times.length; i++) {
      const time = times[i]
      const endTime = new Date(new Date(`1970-01-01T${time}`).getTime() + lessonType.length * 60 * 1000)
      for (let j = i + 1; j < times.length; j++) {
        const otherTime = times[j]
        const otherEndTime = new Date(new Date(`1970-01-01T${otherTime}`).getTime() + lessonType.length * 60 * 1000)
        if (endTime > new Date(`1970-01-01T${otherTime}`) && new Date(`1970-01-01T${time}`) < otherEndTime) {
          overlaps.push(`${day.when} - ${time}`)
        }
      }
    }
  })

  // return deduped overlaps array using set
  return [...new Set(overlaps)]
}

export function lessonTypePackages(lessonType, instructor) {
  const result = []
  Object.values(lessonType.pricing).filter(pricingType => pricingType.packages?.length).forEach((pricingType) => {
    pricingType.packages.forEach((lessonPackage) => {
      const instructorData = instructor
        ? {
            name: instructor.name,
            photoURL: instructor.photoURL,
            id: instructor.id,
          }
        : null
      result.push({
        name: pricingType.name,
        lessons: lessonPackage.lessons,
        price: lessonPackage.price,
        location: lessonType.location,
        instructor: instructorData,
      })
    })
  })
  // sort by the order of the pricing types in ALL_PRICING_TYPES, then by number of lessons
  result.sort((a, b) => {
    const allPricingNames = Object.values(ALL_PRICING_TYPES).map(pricingType => pricingType.name)
    const aIndex = allPricingNames.indexOf(a.name)
    const bIndex = allPricingNames.indexOf(b.name)
    return (aIndex - bIndex) || (a.lessons - b.lessons)
  })
  return result
}
