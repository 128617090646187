import { alertController, loadingController } from '@ionic/vue'

import { reportError } from '@/firebase'

async function createErrorAlert(error, lesson) {
  console.error(error)
  return await alertController.create({
    header: '😢 Unexpected Error',
    // subHeader: 'A Sub Header Is Optional',
    message: 'The SwingMatch team has been notified about the error. Please try again.',
    buttons: [{
      text: 'Send Error Report',
      role: 'confirm',
      handler: async (values) => {
        error = { message: error.message, stack: error.stack }
        await reportError({ error, description: values.description, lesson })
      },
    }],
    backdropDismiss: false,
    inputs: [{
      type: 'textarea',
      name: 'description',
      placeholder: 'Extra information about the error (optional)',
    }],
  })
}

/**
 * Sends a request and handles loading, error, and dismissal of loading.
 *
 * @param {string} message - The message to display in the loading spinner.
 * @param {Function} fn - The function to execute for the request.
 * @param {string} lesson - The lesson associated with the request.
 * @returns {Promise<void>} - A promise that resolves when the request is completed.
 * @example await sendRequest('Loading...', async () => await someFunction(), lessonObject)
 */
export async function sendRequest(message, fn, lesson) {
  const loading = await loadingController.create({ message: message })
  try {
    loading.present()
    await fn()
  } catch (error) {
    const alert = await createErrorAlert(error, lesson)
    await alert.present()
  } finally {
    loading.dismiss()
  }
}
