/**
 * Generates a custom ID combining a sortable timestamp and a unique suffix.
 * @param {number} [length=4] - The length of the unique suffix.
 * @param {number} [epoch=2000000000] - The epoch time to subtract from (default is roughly year 2033).
 * @returns {string} The generated custom ID.
 */
exports.generateId = (length = 4, epoch = 2000000000) => {
  // Generate a compact sortable timestamp prefix
  const now = Date.now() / 1000 // Current time in seconds
  const timestamp = Math.floor(epoch - now).toString(36)

  // Generate a unique suffix of specified length
  const uniqueSuffix = Math.random().toString(36).substring(2, 2 + length)

  // Combine the timestamp and unique ID
  return `${timestamp}${uniqueSuffix}`
}

// Example usage with outputs:
// Assuming the current timestamp is 2023-09-24 12:00:00 UTC

// const id1 = generateId();
// Output: "4qpvl7a1b2" (timestamp: "4qpvl7", suffix: "a1b2")

// const id2 = generateId(6);
// Output: "4qpvl7a1b2c3" (timestamp: "4qpvl7", suffix: "a1b2c3")

// const id3 = generateId(4, 2100000000);
// Output: "8hijkla1b2" (timestamp: "8hijkl", suffix: "a1b2")

// Note: The unique suffixes (a1b2, a1b2c3) will be different in each run
// The timestamp part will decrease over time as we approach the epoch
