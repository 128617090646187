<template>
  <div class="ion-padding">
    <h3>Financial Statements</h3>
    <ion-list v-if="statements?.length">
      <ion-item
        v-for="statement in statements" :key="statement.id" lines="full"
      >
        <ion-label>
          <h2>{{ statement.date }}</h2>
          <p class="created-at">Created: {{ formatDate(statement.createdAt.seconds * 1000, 'PPP') }}</p>
          <p><strong>Comments:</strong> {{ statement.comments }}</p>
        </ion-label>
        <a
          slot="end" :href="statement.fileUrl" fill="clear"
          target="_blank"
        >
          <ion-icon :icon="downloadOutline" size="large" />
        </a>
      </ion-item>
    </ion-list>

    <div v-else class="ion-text-center ion-padding">
      <ion-icon :icon="documentOutline" size="large" color="medium" />
      <p>No financial statements available at this time</p>
    </div>
  </div>
</template>

<script setup>
import { IonIcon, IonItem, IonLabel, IonList } from '@ionic/vue'
import { documentOutline, downloadOutline } from 'ionicons/icons'
import { useCollection, useCurrentUser } from 'vuefire'
import { collection } from 'firebase/firestore'
import { formatDate } from 'date-fns'

import { db } from '@/firebase'

const currentUser = useCurrentUser()
const statements = useCollection(collection(db, `users/${currentUser.value.uid}/statements`))

</script>
