<template>
  <div class="ion-padding" style="background-color: var(--ion-color-light);" data-testid="reset-password-modal">
    <h1 class="ion-text-center ion-no-margin ion-margin-bottom">Reset Password</h1>
    <ion-list>
      <ion-input
        v-model="email"
        lines="none" type="email" placeholder="Enter your email"
        label="" autocomplete="email" required
        name="email"
      />
    </ion-list>
    <ion-button expand="block" @click="resetPassword">Reset Password</ion-button>
  </div>
</template>

<script setup>
import { IonButton, IonInput, IonList } from '@ionic/vue'
import { ref } from 'vue'

const email = ref('')
const emit = defineEmits(['reset'])

import { sendPasswordResetEmail } from 'firebase/auth'
import { useFirebaseAuth } from 'vuefire'
const auth = useFirebaseAuth()

function resetPassword() {
  sendPasswordResetEmail(auth, email.value)
    .then(() => {
      alert(`Password reset email sent. Please check your ${email.value}.`)
      emit('reset')
    })
    .catch((error) => {
      if (error.code === 'auth/invalid-email') alert('Invalid email')
      console.error(error)
    })
}
</script>

<style>
/* CAN NOT BE SCOPED */
ion-modal#reset-password-modal {
  --width: 90%;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 14px;
}
</style>
