<template>
  <ion-page data-testid="financial-reports-page">
    <ion-header>
      <ion-toolbar>
        <ion-segment v-model="subpage" scrollable="true" data-testid="reports-page-menu">
          <ion-segment-button value="summary">
            <ion-label>
              Summary
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="lessons">
            <ion-label>
              Lessons
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="packages">
            <ion-label>
              Packages
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="statements">
            <ion-label>
              Statements
            </ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light">
      <summary-page
        v-if="subpage === 'summary'"
        :lessons="completedLessons"
      />
      <template v-if="subpage === 'lessons'">
        <ion-grid>
          <ion-row>
            <ion-col>
              <h3 style="font-weight: normal;">{{ filteredLessons.length }} Completed Lesson{{ filteredLessons.length === 1 ? '' : 's' }}</h3>
            </ion-col>
            <ion-col size="auto">
              <h3 style="font-weight: normal;">{{ formatMoney(getTotalEarnings(filteredLessons)) }}</h3>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-segment v-model="paymentMethodFilter" scrollable="true">
          <ion-segment-button value="all">
            <ion-label>
              All
              <ion-badge color="light">{{ paymentMethodsBreakdown['all']?.length }}</ion-badge>
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="creditCard">
            <ion-label>
              Online
              <ion-badge color="light">{{ paymentMethodsBreakdown['creditCard']?.length }}</ion-badge>
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="offline">
            <ion-label>
              Offline
              <ion-badge color="light">{{ paymentMethodsBreakdown['offline']?.length }}</ion-badge>
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="package">
            <ion-label>
              Package
              <ion-badge color="light">{{ paymentMethodsBreakdown['package']?.length }}</ion-badge>
            </ion-label>
          </ion-segment-button>
        </ion-segment>
        <ion-segment v-model="paymentStatusFilter">
          <ion-segment-button value="paid">
            <ion-label>
              Paid
              <ion-badge color="light">{{ paymentStatusBreakdown['paid'] }}</ion-badge>
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="authorized">
            <ion-label>
              Unpaid
              <ion-badge color="light">{{ paymentStatusBreakdown['authorized'] }}</ion-badge>
            </ion-label>
          </ion-segment-button>
        </ion-segment>
        <ion-list v-if="lessonsGroupsByMonths.length > 0">
          <ion-item-group v-for="([month, totalEarnings, lessons]) in lessonsGroupsByMonths" :key="month">
            <ion-item-divider sticky="true" style="--inner-padding-end: 0;">
              <ion-grid style="padding-left: 0; padding-right: 0">
                <ion-row>
                  <ion-col style="padding-left: 0; font-weight: normal;">
                    {{ month }}
                  </ion-col>
                  <ion-col v-if="totalEarnings != 0" size="auto" style="padding-right: 0;text-align: right; font-weight: normal;">
                    {{ formatMoney(totalEarnings) }}
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-item-divider>
            <lesson-row v-for="lesson in lessons" :key="lesson.id" :lesson="lesson" />
          </ion-item-group>
        </ion-list>
        <div v-else class="ion-text-center ion-padding ion-margin-top">
          <ion-icon :icon="golfOutline" size="large" color="medium" />
          <p>No completed lessons yet</p>
        </div>
      </template>
      <packages-page v-if="subpage === 'packages'" />
      <statements-list v-if="subpage === 'statements' || $route.hash === '#statements'" />
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonBadge, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItemDivider, IonItemGroup, IonLabel, IonList, IonPage, IonRow, IonSegment, IonSegmentButton, IonToolbar } from '@ionic/vue'
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { golfOutline } from 'ionicons/icons'

import LessonRow from './LessonRow.vue'
import { LessonsRepository } from '@/data/LessonsRepository'
import PackagesPage from './PackagesPage.vue'
import StatementsList from './StatementsList.vue'
import SummaryPage from './SummaryPage.vue'
import { formatMoney } from '@/shared/utils/formatMoney'

const route = useRoute()
const router = useRouter()
const paymentMethodFilter = ref('all')
const paymentStatusFilter = ref('paid')
const subpage = ref(route.hash.replace('#', '') || 'summary')

watch(subpage, (newVal) => {
  router.replace({ hash: `#${newVal}` })
})

const lessonsRepo = new LessonsRepository()
const instructorLessons = lessonsRepo.getInstructorLessons()

const completedLessons = computed(() => {
  return instructorLessons.value.filter(lesson => lesson.stateGroup() === 'completed').sort((a, b) => b.date.localeCompare(a.date))
})

const filteredLessons = computed(() => {
  const lessons = paymentMethodsBreakdown.value[paymentMethodFilter.value] || []
  return lessons.filter((lesson) => {
    return lesson.paymentInfoObject().status === paymentStatusFilter.value
  })
})

const lessonsGroupsByMonths = computed(() => {
  const lessonsGroups = filteredLessons.value.reduce((acc, lesson) => {
    const month = lesson.date.split('-')[1]
    const year = lesson.date.split('-')[0]
    const monthYear = `${year}-${month}`
    if (!acc[monthYear]) {
      acc[monthYear] = []
    }
    acc[monthYear].push(lesson)
    return acc
  }, {})
  // sort by month and replace month with month name
  return Object.entries(lessonsGroups).sort((a, b) => b[0].localeCompare(a[0])).map(([monthYear, lessons]) => {
    const monthName = new Date(`${monthYear}-15`).toLocaleString('en-US', { month: 'long', year: 'numeric' })
    return [monthName, getTotalEarnings(lessons), lessons]
  })
})

function getTotalEarnings(lessons) {
  return lessons.reduce((total, lesson) => {
    return total + lesson.paymentInfoObject().price
  }, 0).toFixed(2)
}

const paymentMethodsBreakdown = computed(() => {
  const result = completedLessons.value.reduce((acc, lesson) => {
    acc[lesson.paymentInfoObject().method] ||= []
    acc[lesson.paymentInfoObject().method].push(lesson)
    return acc
  }, {})
  result.all = completedLessons.value
  return result
})

const paymentStatusBreakdown = computed(() => {
  const lessons = paymentMethodsBreakdown.value[paymentMethodFilter.value] || []
  return lessons.reduce((acc, lesson) => {
    acc[lesson.paymentInfoObject().status] ||= 0
    acc[lesson.paymentInfoObject().status] += 1
    return acc
  }, {})
})
</script>

<style scoped>
ion-item-divider {
  --padding-start: 1rem;
  --padding-end: 1rem;
}
</style>
